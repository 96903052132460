import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { IconButton, Box, Menu, MenuItem, Typography, Link } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


const CalificacionesTable = ({ prueba, users, tallerTitle }) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const columns = [
        {
            field: 'id',
            disableColumnMenu: true,
            headerName: 'ID',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'block_id',
            disableColumnMenu: true,
            headerName: 'Pregunta',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'type',
            disableColumnMenu: true,
            headerName: 'Tipo',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'answers_total',
            disableColumnMenu: true,
            headerName: 'N° Respuestas',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => Object.keys(row.answers).length
        },
        {
            field: 'answers_rate',
            disableColumnMenu: true,
            headerName: 'Tasa de respuesta',
            sortable: false,
            width: 140,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => (100*(Object.keys(row.answers).length / users.length)).toString().slice(0, 4) + "%"
        },

        {
            field: 'correct_rate',
            disableColumnMenu: true,
            headerName: 'Tasa de correctitud',
            sortable: false,
            width: 140,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => (100*(Object.keys(row.answers).filter(user => row.answers[user]["is_correct"]).length / Object.keys(row.answers).length)).toString().slice(0, 4) + "%"
        },
        {
            field: 'detail',
            disableColumnMenu: true,
            headerName: 'Detalles',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
    ]

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>{tallerTitle ? "Taller " + prueba.name : prueba.name}</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "20px 0" }}>
            {prueba && <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={Object.values(prueba.questions)}
                columns={columns}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
            }
        </div>
    );
};

export default CalificacionesTable;
