import axios from 'axios';

export const getEncuestasQuestions = async (token, course, implementation, type) => {

    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "/encuestas_questions", {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });

        switch (response.status) {
            case 200:
                return { courseData: response.data, error: "" };
            default:
                return { courseData: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { courseData: null, error: "Error desconocido: " + error.message };
    }
}