import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const Modal = ({ open, handleClose, title, content1, content2 }) => {


    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, "color": "#646464", "fontFamily": "DIN Bold" }}>
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{"textAlign": "justify", "color": "#646464"}}>
                {content1}
            </DialogContent>
            <DialogContent sx={{"textAlign": "justify", "color": "#646464"}}>
                {content2}
            </DialogContent>
        </Dialog>
    );
}

export default Modal;