import React from 'react';
import { Typography } from '@mui/material';
import SummaryTable from './tables/SummaryTable';


const Summary = ({ courses }) => {


    return (
        <>
            <Typography fontSize={"1.6em"} mb={"10px"} variant="title">Suma y Sigue</Typography>
            <Typography fontSize={"1.0em"} variant="title">Plataforma de datos y seguimiento</Typography>
            <SummaryTable data={courses} />
        </>
    );

}

export default Summary;