import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';


const ImplementationForm = ({
    course,
    isDiplomado,
    selectedImplementation,
    setSelectedImplementation,
    selectedSection,
    setSelectedSection,
    fetchData
}) => {


    return (
        <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"center"} m={"15px 0"} width={"100%"}>
            <Grid item xs={6} md={4}>
                <TextField
                    label="Implementación"
                    value={selectedImplementation}
                    select
                    fullWidth
                    onChange={(e) => {
                        setSelectedSection("none");
                        setSelectedImplementation(e.target.value);
                        if (e.target.value !== "none") {
                            fetchData(e.target.value);
                        }
                    }}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {isDiplomado ?
                        course.modules[Object.keys(course.modules)[0]].implementations.map((imp, index) => (
                            <MenuItem key={index} value={index}>
                                {imp.semester}
                            </MenuItem>
                        ))
                        :
                        course.implementations.map((imp, index) => (
                            <MenuItem key={index} value={index}>
                                {imp.semester + " — " + imp.type.replace("e-", "E-").replace("b-", "B-")}
                            </MenuItem>
                        ))}
                </TextField>
            </Grid>
            <Grid item xs={6} md={4}>
                <TextField
                    label="Sección"
                    value={selectedSection}
                    select
                    fullWidth
                    disabled={selectedImplementation === "none"}
                    onChange={(e) => setSelectedSection(e.target.value)}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {isDiplomado ?
                        course.modules[Object.keys(course.modules)[0]].implementations[selectedImplementation] && course.modules[Object.keys(course.modules)[0]].implementations[selectedImplementation].sections.map((sec, index) => (
                            <MenuItem key={index} value={index}>{sec.section}</MenuItem>
                        ))
                        :
                        course.implementations[selectedImplementation] && course.implementations[selectedImplementation].sections.map((sec, index) => (
                            <MenuItem key={index} value={index}>{sec.location + " " + sec.section}</MenuItem>
                        ))}
                </TextField>
            </Grid>
        </Grid>
    )
}

export default ImplementationForm;