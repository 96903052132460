import React, { cloneElement } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import theme from './theme';
import NotFound from './Errors/NotFound';
import AdminWrapper from './Auth/AdminWrapper';
import Home from './Content/Home/Home';
import Historia from './Content/Home/Historia';
import Basica from './Content/Home/Basica';
import Media from './Content/Home/Media';
import Diplomas from './Content/Home/Diplomas';
import Trayectorias from './Content/Home/Trayectorias';
import TrayectoriasForm from './Content/Home/TrayectoriasForm';
import { ThemeProvider } from '@mui/material/styles';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import { KeycloakProvider, useKeycloak } from "keycloak-react-web"
import Keycloak from 'keycloak-js';
import SilentSSOCheck from './Auth/SilentSSOCheck';
import oidcConfiguration from './Auth/oidcConfig';
import matomoConfiguration from './Auth/matomoConfig';

const keycloakSetting = oidcConfiguration;
const matomoSetting = matomoConfiguration;

const authInstance = new Keycloak(keycloakSetting);
const matomoInstance = createInstance(matomoSetting);

function App() {
  return (
    <KeycloakProvider client={authInstance} initOptions={{
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    }}>
      <MatomoProvider value={matomoInstance}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/historia" element={<Historia/>} />
              <Route path="/basica" element={<Basica/>} />
              <Route path="/media" element={<Media/>} />
              <Route path="/diplomas" element={<Diplomas/>} />
              <Route path="/trayectorias" element={<Trayectorias/>} />
              <Route path="/trayectorias/formulario" element={<TrayectoriasForm/>} />
              <Route path="/admin" element={<ProtectedRoute><AdminWrapper /></ProtectedRoute>} />
              <Route path="/silent-check-sso.html" element={<SilentSSOCheck  />} />
              <Route path="*" element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
            </Routes>
          </Router>
        </ThemeProvider>
      </MatomoProvider>
    </KeycloakProvider>
  );
}

const ProtectedRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(50);
  };

  const childWithProps = React.Children.map(children, child => {
      return cloneElement(child, { keycloak: keycloak });
  });

  return <>{childWithProps}</>;
};

export default App;