import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography, Grid, Box, List, ListItem } from '@mui/material';
import CoursePreview from './components/CoursePreview';
import vrgImage from '../../assets/img/VRG.png';
import iepImage from '../../assets/img/IEP.png';
import dmfImage from '../../assets/img/DMC.png';


const Media = ({ }) => {

    const { trackPageView } = useMatomo();

    useEffect(() => {
        document.title = "Suma y Sigue | Cursos de Media";
        trackPageView();
    }, []);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header current={"media"}/>
            <Grid
                p={"0 20px"}
                component="div"
                sx={{
                flexGrow: 1,
                m: '30px auto',
                maxWidth: '940px',
                width: '100%',
                }}
            >
                <Typography variant='subtitle' fontWeight={"bold"} m={"20px 0"} textAlign={"center"}>
                    CURSOS DE MEDIA
                </Typography>
                <Typography variant='p' m={"20px 0 0 0"}>
                    <b>Modalidad</b>: e-learning (a distancia) o b-learning (semi-presencial).
                </Typography>
                <Typography variant='p'>
                    <b>Duración</b>: 34 - 45 horas distribuidas entre 2 a 3 meses.
                </Typography>
                <Typography variant='p' m={"20px 0"}>
                    Los cursos tienen un alto componente de trabajo virtual autónomo basado en el uso de tecnologías y resolución de problemas, que entregan
                    experiencias de aprendizaje activo.
                </Typography>
                <Typography variant='p' m={"20px 0"}>
                    Los cursos cuentan con dos formatos de trabajo:
                </Typography>
                <Box ml={"20px"}>
                    <List sx={{ listStyleType: 'disc' }}>
                        <ListItem sx={{ display: 'list-item', color: '#646464' }}>
                            <Typography variant='p'>
                                <b>Talleres virtuales asincrónicos</b>: espacios de trabajo autónomo enfocados en desarrollar la comprensión matemática de diferentes temas
                                fundamentales del currículum escolar y la construcción de conocimientos a partir de contextos cotidianos, de otras disciplinas, de la
                                enseñanza e imaginativos.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#646464' }}>
                            <Typography variant='p'>
                                <b>Sesiones de trabajo sincrónico</b>: talleres de discusión en los que se promueve la construcción colaborativa de conocimientos y la reflexión
                                docente. Estos pueden ser realizados de manera presencial o virtual.
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
                <Grid container display={"flex"} alignItems={"center"} justifyContent={"space-between"} mt={"20px"} flexWrap={"wrap"} spacing={4}>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Desarrollando la Visualización y el Razonamiento Geométrico 3D"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 3° y 4° medio."
                            image={vrgImage}
                            paragraph1="El curso Desarrollando la Visualización y el Razonamiento Geométrico 3D se enfoca en desarrollar los conocimientos 
                            matemáticos requeridos para enseñar geometría en los niveles de 3° a 4° medio."
                            paragraph2="El curso aborda el razonamiento geométrico espacial, poniendo énfasis en la visualización y en la relación entre figuras 
                            2D y 3D, el proceso de medir y sus propiedades, con el fin de dar sentido al volumen como una magnitud inherente a los cuerpos 
                            geométricos. Además, se desarrolla el concepto de vector, el concepto de homotecia de manera vectorial y las relaciones entre rectas 
                            y planos mediante su representación vectorial."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Innovando en la Enseñanza de las Probabilidades"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 1° a 3° medio."
                            image={iepImage}
                            paragraph1="El curso Innovando en la Enseñanza de las Probabilidades se enfoca en desarrollar los conocimientos matemáticos requeridos 
                            para enseñar probabilidades en los niveles de 1° a 3° medio."
                            paragraph2="El curso aborda los enfoques frecuentista y clásico de la probabilidad, los axiomas de probabilidad, la probabilidad 
                            condicional y el sesgo de equiprobabilidad. Además, se trabaja en la elaboración de simulaciones de experimentos aleatorios y en el 
                            desarrollo de técnicas de conteo. "
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Desarrollando la Habilidad de Modelar con Funciones"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 3° y 4° medio."
                            image={dmfImage}
                            paragraph1="El curso Desarrollando la Habilidad de Modelar con Funciones se enfoca en desarrollar los conocimientos matemáticos requeridos para 
                            trabajar la habilidad de modelar en los niveles de 1° a 4° medio."
                            paragraph2="El curso aborda el ciclo de modelamiento matemático, la selección y formulación de tareas de modelamiento y aspectos a 
                            considerar en la preparación e implementación de este tipo de actividades en el aula escolar."
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );

};

export default Media;