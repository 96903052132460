import React, { useState, useEffect } from 'react';
import { getLevelChip, getTopicChip } from '../../../utils/chips';
import { Typography, Box, Tab, Tabs } from '@mui/material';
import { getCourseData } from '../../../requests/getCourseData';
import ImplementationForm from './forms/ImplementationForm';
import CourseResumen from './course/CourseResumen';
import CourseAvance from './course/CourseAvance';
import CourseEncuestas from './course/CourseEncuestas';
import CourseCalificaciones from './course/CourseCalificaciones';
import CourseExplorador from './course/CourseExplorador';
import CourseReviewIndicator from './indicators/CourseReviewIndicator';

function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Course = ({ keycloak, course, selectedImplementation, setSelectedImplementation, selectedSection, setSelectedSection, encuestasQuestions }) => {

    const [courseData, setCourseData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState("resumen");

    const handleTabs = (event, newValue) => {
        setTab(newValue);
    }

    const fetchData = async (index) => {
        setLoading(true);
        const { courseData, error } = await getCourseData(keycloak.token, course.id, course.implementations[index]["semester"], course.implementations[index]["type"]);
        if (error) {
            console.log(error);
            return;
        }
        setCourseData(courseData);
        setLoading(false);
    }

    useEffect(() => {
        setCourseData(null);
        setTab("resumen");
    }, [course]);

    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"} mb={"20px"} width={"100%"}>
                <Box mr={"20px"} width={"100%"}>
                    <Typography fontSize={"1.6em"} variant="title" mb={0}>{course.name}</Typography>
                    <Box display={"flex"} justifyContent={"start"} mb={"20px"} mt={"10px"}>
                        <Box mr={"10px"}>
                            {getLevelChip(course.level)}
                        </Box>
                        <Box>
                            {getTopicChip(course.topic)}
                        </Box>
                    </Box>
                    <Typography variant='p-small'>{course.description}</Typography>
                    <ImplementationForm
                        course={course}
                        isDiplomado={false}
                        selectedImplementation={selectedImplementation}
                        setSelectedImplementation={setSelectedImplementation}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                        fetchData={fetchData}
                    />
                </Box>
                <CourseReviewIndicator course={course} />
            </Box>
            <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={tab} onChange={handleTabs} sx={{
                "&& .Mui-selected": {
                    color: 'white',
                    backgroundColor: '#d23831',
                },
                '& .MuiTabs-flexContainer': {
                    flexWrap: 'wrap',
                },
                marginTop: "10px",
                marginBottom: "20px"
            }}>
                <Tab label={
                    <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                        Resumen
                    </div>
                }
                    value={"resumen"} />
                <Tab label={
                    <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                        Avance
                    </div>
                }
                    value={"avance"} />
                <Tab
                    label={
                        <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                            Encuestas e instrumentos
                        </div>
                    }
                    value={"encuestas"}
                />
                <Tab
                    label={
                        <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                            Calificaciones
                        </div>
                    }
                    value={"calificaciones"}
                />
                <Tab
                    label={
                        <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                            Explorador de respuestas
                        </div>
                    }
                    value={"explorador"}
                />
            </Tabs>
            <CustomTabPanel value={tab} index={"resumen"}>
                <CourseResumen
                    keycloak={keycloak}
                    course={course}
                    courseData={courseData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={"avance"}>
                <CourseAvance
                    course={course}
                    courseData={courseData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={"encuestas"}>
                <CourseEncuestas
                    course={course}
                    courseData={courseData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                    encuestasQuestions={encuestasQuestions}
                />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={"calificaciones"}>
                <CourseCalificaciones
                    course={course}
                    courseData={courseData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={"explorador"}>
                <CourseExplorador
                    course={course}
                    courseData={courseData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                />
            </CustomTabPanel>
        </>
    );

}

export default Course;