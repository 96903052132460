export const downloadOutputFileDiplomado = async (token, course, implementation, module, section, file) => {
    fetch(process.env.REACT_APP_API_URL + "/diplomados/" + course + "/" + implementation + "/" + module + "/" + section + "/" + file, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => response.blob())
        .then(blob => {
            var format;
            if (file === "report" || file === "encuesta") {
                format = "xlsx";
            } else {
                format = "json";
            }
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file}_${course}_${module}_${implementation.replace("-", "_")}_${section}.${format}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading file:', error));
}