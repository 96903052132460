import React, { useState } from 'react';
import AvanceTable from '../tables/AvanceTable';
import { Grid, Box, CircularProgress, Typography, List, ListItem, ListItemButton, Divider } from '@mui/material';
import { formatCourseStructure } from '../../../../utils/formatters';


const CourseAvance = ({
    course,
    courseData,
    loading,
    selectedImplementation,
    selectedSection,
}) => {

    const [avanceTab, setAvanceTab] = useState("Resumen");

    const handleAvanceTabClick = (tab) => {
        setAvanceTab(tab);
    }

    return (
        selectedImplementation === "none" ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p' textAlign={"center"}>Por favor seleccione una implementación.</Typography>
            </Box>
            : selectedSection === "none" ?
                <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                    <Typography variant='p' textAlign={"center"}>Por favor seleccione una sección.</Typography>
                </Box>
                : loading ?
                    <Box width={"100%"} textAlign={"center"} mt={"200px"}>
                        <CircularProgress sx={{ "color": "#d23831" }} />
                    </Box>
                    : Object.keys(courseData).length === 0 ?
                        <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                            <Typography variant='p' textAlign={"center"}>No hay información disponible para esta implementación.</Typography>
                        </Box>
                        : (courseData[Object.keys(courseData)[selectedSection]].report == null || courseData[Object.keys(courseData)[selectedSection]].structure == null) ?
                            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                                <Typography variant='p' textAlign={"center"}>No hay datos de avance para esta sección.</Typography>
                            </Box>
                            :
                            <Box width={"100%"}>
                                <Grid container spacing={2} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                                    <Grid item xs={12} md={2}>
                                        <List>
                                            <ListItem key={"Resumen"} disablePadding sx={{ "backgroundColor": avanceTab === "Resumen" ? "#60696C" : "white" }}>
                                                <ListItemButton onClick={() => handleAvanceTabClick("Resumen")}>
                                                    <Typography variant="p" sx={{ "textAlign": "center", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={avanceTab === "Resumen" ? "white" : "#646464"}>Resumen de avance</Typography>
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                        <Divider />
                                        <Typography variant="p" sx={{
                                            color: "#646464",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            fontSize: "0.9em",
                                            marginTop: "15px",
                                            fontFamily: "Avenir Heavy"
                                        }}>Taller</Typography>
                                        <List>
                                            {Object.values(formatCourseStructure(courseData[Object.keys(courseData)[selectedSection]].structure)).map(taller => (
                                                (taller.taller !== -1 && !taller.presencial) &&
                                                <ListItem key={taller.section} disablePadding sx={{ "backgroundColor": avanceTab === taller.section ? "#60696C" : "white" }}>
                                                    <ListItemButton onClick={() => handleAvanceTabClick(taller.section)}>
                                                        <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={avanceTab === taller.section ? "white" : "#646464"}>{taller.taller + ". " + taller.section}</Typography>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        {avanceTab == null ?
                                            <Box width={"100%"} textAlign={"center"}>
                                                <Typography variant='p' textAlign={"center"}>Por favor seleccione un taller.</Typography>
                                            </Box>
                                            : avanceTab === "Resumen" ?
                                                <Box>
                                                    <Typography variant="p" sx={{ "textAlign": "center", "margin": "20px 0" }}>Resumen de avance</Typography>
                                                </Box>
                                                :
                                                Object.values(formatCourseStructure(courseData[Object.keys(courseData)[selectedSection]].structure)).filter(s => s.section === avanceTab)[0].subsections.map(subsection => (
                                                    !subsection.control && <Box key={subsection.subsection} mb={"30px"}>
                                                        <Typography fontSize={"1.4em"} variant='subtitle'>{"T" + subsection.taller + "A" + subsection.actividad + ". " + subsection.subsection}</Typography>
                                                        <AvanceTable
                                                            respuestas={courseData[Object.keys(courseData)[selectedSection]].respuestas}
                                                            subsection={subsection}
                                                        />
                                                    </Box>
                                                ))
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
    );

}

export default CourseAvance;