import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { IconButton, Box, Menu, MenuItem, Typography, Link } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


const AvanceTable = ({ respuestas, subsection }) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    function makeColumns() {
        let columns = [
            {
                field: 'username',
                headerName: 'Usuario',
                sortable: false,
                headerAlign: 'center',
                align: 'left'
            }
        ];
        for (let u = 0; u < subsection.units.length; u++) {
            columns.push({
                field: subsection.units[u].unit,
                headerName: 'Pestaña ' + (u + 1),
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    return (
                        <Box display={"flex"} justifyContent={"center"}>
                            {respuestas[params.row.username][subsection.section][subsection.subsection][subsection.units[u].unit] === "true" ?
                                <CheckCircleIcon sx={{ "color": "#4caf50" }} />
                                :
                                <CancelIcon sx={{ "color": "#f44336" }} />
                            }
                        </Box>
                    )
                }
            });
        }
        return columns;
    }

    function makeRows() {
        let rows = [];
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Avance en el curso</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={makeRows()}
                columns={makeColumns()}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    pinnedColumns: { left: ['username'] },
                    sorting: {
                        sortModel: [],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
            />
    );
};

export default AvanceTable;
