import React from 'react';
import { Box, Grid, Typography, Card } from '@mui/material';
import { xblockTypes } from '../../../../utils/lists';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


const XblockResults = ({ xblock }) => {


    return (
        <Card sx={{ "margin": "10px 10px", "minWidth": "500px", "width": "500px"}}>
            <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ "height": "30px", "backgroundColor": "#d23831", "paddingTop": "10px" }}>
                <Typography variant={'p'} color="white" fontWeight={"bold"} gutterBottom width={"100%"} textAlign={"center"} fontSize={"0.8em"}>
                    Características del XBlock
                </Typography>
            </Grid>
            <Box p={"10px"}>
                <Grid container spacing={2}>
                    <Grid item xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Tipo</Typography>
                    </Grid>
                    <Grid item xs={8} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblockTypes[xblock.block_type] ? xblockTypes[xblock.block_type] : xblock.block_type}</Typography>
                    </Grid>
                    <Grid item xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">URL (LMS)</Typography>
                    </Grid>
                    <Grid item xs={8} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.block_id}</Typography>
                    </Grid>
                    <Grid item xs={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">URL (Studio)</Typography>
                    </Grid>
                    <Grid item xs={8} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.block_id}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Actividad</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">ORA</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Reglamento</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Consentimiento</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_activity ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_ora ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_reglamento ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_consentimiento ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Encuesta</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Control</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Taller</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Calificada</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_encuesta ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_control ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_taller ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_pregunta_calificada ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Pre-test</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Post-test</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Módulo inicial</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontWeight={"bold"} variant="p-small">Módulo final</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_pretest ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_postest ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_modulo_inicial ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                    <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="p-small">{xblock.is_modulo_final ? <CheckCircleIcon sx={{ color: '#088408' }} /> : <CancelIcon sx={{ color: '#ff0000' }} />}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );

}

export default XblockResults;