import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../Header/Header';
import Admin from '../Content/Admin/Admin';
import { Grid, Typography, Button, Box } from '@mui/material';


const AdminWrapper = ({ keycloak }) => {

  const [permissions, setPermissions] = useState(null);
  const [keycloakReady, setKeycloakReady] = useState(false);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    document.title = "Suma y Sigue | Panel de administración";
    if (keycloak.didInitialize){
      trackPageView();
      if (keycloak.authenticated) {
        keycloak.loadUserProfile().then(function (profile) {
          setKeycloakReady(true);
          setPermissions({
            canSeeAdmin: true,
            dashboards: (profile.attributes && profile.attributes.sys_dashboards) ? profile.attributes.sys_dashboards[0].split(",") : [],
          });
        });
      } else {
        setKeycloakReady(true);
      }
    }
  }, [keycloak.authenticated]);

  return (
    (permissions && permissions.canSeeAdmin) ? <Admin keycloak={keycloak} permissions={permissions} ></Admin> : keycloakReady ?
      <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <Grid
          p={"0 20px"}
          component="div"
          sx={{
            flexGrow: 1,
            m: '30px auto',
            maxWidth: '1400px',
            width: '100%',
          }}
        >
          <Box textAlign="center">
            <Typography variant='title' fontSize={"1.6em"} textAlign={"center"}>Por favor ingrese para acceder a esta página.</Typography>
            <Button onClick={() => keycloak.login()} variant='red'>Iniciar sesión</Button>
          </Box>
        </Grid>
      </Grid>
      : <></>
  );

}

export default AdminWrapper;