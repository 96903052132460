import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import OutputTable from '../tables/OutputTable';


const CourseResumen = ({
    keycloak,
    course,
    courseData,
    loading,
    selectedImplementation,
    selectedSection,
}) => {

    return (
        selectedImplementation === "none" ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant={"p"} textAlign={"center"}>Por favor seleccione una implementación.</Typography>
            </Box>
            : loading ?
                <Box width={"100%"} textAlign={"center"} mt={"200px"}>
                    <CircularProgress sx={{ "color": "#d23831" }} />
                </Box>
                : courseData ?
                    <Box width={"100%"} display={"flex"} justifyContent={"start"}>
                        <OutputTable keycloak={keycloak} course={course} implementation={selectedImplementation} data={courseData} isDiplomado={false} />
                    </Box>
                    :
                    <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                        <Typography variant={"p"} textAlign={"center"}>No hay información disponible</Typography>
                    </Box>
    );

}

export default CourseResumen;