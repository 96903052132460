import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography, Grid, Box } from '@mui/material';
import bannerImage from '../../assets/img/banner.png';
import statsImage1 from '../../assets/img/ss01.png';
import statsImage2 from '../../assets/img/ss02.png';
import statsImage3 from '../../assets/img/ss03.png';
import statsImage4 from '../../assets/img/ss04.png';
import statsImage5 from '../../assets/img/ss05.png';

const Home = ({ }) => {

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Grid
        p={"0 20px"}
        component="div"
        sx={{
          flexGrow: 1,
          m: '30px auto',
          maxWidth: '940px',
          width: '100%',
        }}
      >
        <Box
          component="img"
          sx={{
            width: "100%",
            padding: "9px"
          }}
          alt="Suma y Sigue"
          src={bannerImage}
        />
        <Typography variant='p'>
          Programa de desarrollo profesional docente enfocado en fortalecer el conocimiento matemático para enseñar, que busca potenciar las competencias
          y habilidades matemáticas para conducir procesos de enseñanza y aprendizaje efectivos. Cuenta con 13 cursos dirigidos a docentes de Educación
          Básica y 3 cursos para docentes de Educación Media organizados por eje y niveles escolares. Se imparte en modalidad e-learning y b-learning.
        </Typography>
        <Typography variant='p' fontWeight={"bold"} m={"20px 0"}>
          Conoce más de nuestro programa en el siguiente vídeo:
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <video controls width={"80%"}>
            <source src="https://static.sumaysigue.uchile.cl/home/videos/Suma%20y%20Sigue%20-%20Video%20Foco%20-%20Promocional_2022.mp4" type="video/mp4" />
            Este navegador no soporta la etiqueta de vídeo.
          </video>
        </Box>
        <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
          PRINCIPIOS
        </Typography>
        <Grid container spacing={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs={1}>
            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>1</Typography>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='p'>
              Promueve la reflexión, el análisis y sistematización del conocimiento matemático a través de una variedad de instancias de aprendizaje.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>2</Typography>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='p'>
              Construye el conocimiento matemático a partir de situaciones contextualizadas y pertinentes a la enseñanza.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>3</Typography>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='p'>
              Desarrolla conocimientos matemáticos especializados para la enseñanza.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>4</Typography>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='p'>
              Utiliza la visualización como una herramienta matemática fundamental, a partir de la interacción con diversos recursos tecnológicos.
            </Typography>
          </Grid>
        </Grid>
        <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
          IMPACTO
        </Typography>
        <Grid container spacing={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs={12} sm={3.2}>
            <Box
              component="img"
              sx={{
                width: "100%",
                padding: "9px"
              }}
              alt="Suma y Sigue"
              src={statsImage1}
            />
          </Grid>
          <Grid item xs={12} sm={3.2}>
            <Box
              component="img"
              sx={{
                width: "100%",
                padding: "9px"
              }}
              alt="Suma y Sigue"
              src={statsImage2}
            />
          </Grid>
          <Grid item xs={12} sm={3.2}>
            <Box
              component="img"
              sx={{
                width: "100%",
                padding: "9px"
              }}
              alt="Suma y Sigue"
              src={statsImage3}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"} mt={"10px"}>
          <Grid item xs={12} sm={5}>
            <Box
              component="img"
              sx={{
                width: "100%",
                padding: "9px"
              }}
              alt="Suma y Sigue"
              src={statsImage4}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box
              component="img"
              sx={{
                width: "100%",
                padding: "9px"
              }}
              alt="Suma y Sigue"
              src={statsImage5}
            />
          </Grid>
        </Grid>
        <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
          TESTIMONIOS
        </Typography>
        <Typography variant='p' m={"20px 0"}>
          Conoce aquí los testimonios de docentes que han participado en el programa Suma y Sigue:
        </Typography>
        <Grid container spacing={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs={12} sm={6}>
            <video controls width={"100%"}>
              <source src="https://static.sumaysigue.uchile.cl/home/videos/VIDEO_3_v.3_b%C3%A1sica.mp4" type="video/mp4" />
              Este navegador no soporta la etiqueta de vídeo.
            </video>
          </Grid>
          <Grid item xs={12} sm={6}>
            <video controls width={"100%"}>
              <source src="https://static.sumaysigue.uchile.cl/home/videos/VIDEO_4_corto_v.1_media.mp4" type="video/mp4" />
              Este navegador no soporta la etiqueta de vídeo.
            </video>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Home;