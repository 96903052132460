import axios from 'axios';

export const getCatalog = async (token) => {

    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "/catalog", {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });

        switch (response.status) {
            case 200:
                return { courses: response.data, error: "" };
            default:
                return { courses: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { courses: null, error: "Error desconocido: " + error.message };
    }
}