import React, { useEffect, useState } from 'react';

function SilentSSOCheck() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/silent-check-sso.html')
      .then(response => response.text())
      .then(data => setHtmlContent(data));
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
}

export default SilentSSOCheck;