import React, { useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Modal from './Modal';
import { Typography, Grid, Box } from '@mui/material';


const CoursePreview = ({ title, image, description, paragraph1, paragraph2 }) => {

    const { trackEvent } = useMatomo();

    const [modal, setModal] = useState(false);

    const handleShowModal = () => {
        trackEvent({
            category: 'Modal',
            action: 'Open',
            name: title
        });
        setModal(true);
    }

    const handleHideModal = () => {
        trackEvent({
            category: 'Modal',
            action: 'Close',
            name: title
        });
        setModal(false);
    }

    return (
        <>
            <Grid container sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"center"} onClick={handleShowModal}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: "100%",
                            backgroundColor: "#d23831",
                            display: 'flex',
                            height: "100px",
                            alignItems: 'center',
                        }}
                        alt="Suma y Sigue"
                    >
                        <Typography padding={"10px 20px"} width={"100%"} variant='p' fontWeight={"bold"} m={"20px 0"} textAlign={"center"} color={"white"}>
                            {title}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={11} sx={{
                    height: "250px",
                    margin: "auto",
                    borderLeft: "2px solid #EEEEEE",
                    borderRight: "2px solid #EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "90%",
                            maxHeight: "90%"
                        }}
                        alt="Suma y Sigue"
                        src={image}
                    />
                </Grid>
                <Grid item xs={11} sx={{ backgroundColor: "#EEEEEE" }}>
                    <Typography sx={{ "lineHeight": "1.4" }} variant='p' p={"20px"}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
            <Modal
                open={modal}
                handleClose={handleHideModal}
                title={title}
                content1={paragraph1}
                content2={paragraph2}
            >
            </Modal>
        </>
    );

}

export default CoursePreview;