import React from 'react';
import { Box, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import CloseIcon from '@mui/icons-material/Close';
import EncuestaAnswersTable from '../tables/EncuestaAnswersTable';
import { getChosenAlternatives, formatEncuestaAnswersSeleccionUnica, formatEncuestaAnswersCinta, formatEncuestaAnswerSeleccionMultiple, formatEncuestaAnswersAlternativesSize } from '../../../../utils/formatters';


const EncuestaModal = ({ open, handleClose, title, subtitle, width, selectedQuestion, encuestasQuestions }) => {


    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={width}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "DIN Bold", fontSize: "1.5em" }}>
                {title}
            </DialogTitle>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                {subtitle}
            </DialogContent>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Box>
                    {encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()) &&
                        (selectedQuestion.type === "Selección única" || selectedQuestion.type === "Likert") ?
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant='p' mb={"40px"}>
                                        {encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()).question + (encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()).question_detail !== "—" ? " — " + encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()).question_detail : "")}
                                    </Typography>
                                    <BarChart
                                        dataset={formatEncuestaAnswersSeleccionUnica(selectedQuestion.answers, selectedQuestion, encuestasQuestions)}
                                        yAxis={[{
                                            scaleType: 'band',
                                            dataKey: 'label',
                                            tickLabelStyle: {
                                                fontSize: 10,
                                            },
                                        }]}
                                        layout='horizontal'
                                        series={[{ dataKey: 'value' }]}
                                        colors={['#d23831']}
                                        grid={{ vertical: true }}
                                        slotProps={{
                                            legend: {
                                                hidden: true,
                                            },
                                        }}
                                        margin={{ left: 20 + formatEncuestaAnswersAlternativesSize(selectedQuestion, encuestasQuestions), top: 10, right: 20 }}
                                        {...{
                                            xAxis: [{
                                                label: 'Respuestas',
                                            }],
                                            width: 700,
                                            height: 500,
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={6}>
                                    <EncuestaAnswersTable
                                        data={Object.keys(selectedQuestion.answers).map(user => {
                                            return {
                                                id: user,
                                                answer: getChosenAlternatives(selectedQuestion.answers[user]).length !== 0 ?
                                                    encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase())["alternative_" + getChosenAlternatives(selectedQuestion.answers[user])[0].toLowerCase()] :
                                                    "—"
                                            }
                                        })}

                                        title={"Respuestas"}
                                    />
                                </Grid>
                            </Grid>
                        : selectedQuestion.type === "Selección múltiple" ?
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                <Typography variant='p' mb={"40px"}>{encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()).question}</Typography>
                                <BarChart
                                        dataset={formatEncuestaAnswerSeleccionMultiple(selectedQuestion.answers, selectedQuestion, encuestasQuestions)}
                                        yAxis={[{
                                            scaleType: 'band',
                                            dataKey: 'label',
                                            tickLabelStyle: {
                                                fontSize: 10,
                                            },
                                        }]}
                                        layout='horizontal'
                                        series={[{ dataKey: 'value' }]}
                                        colors={['#d23831']}
                                        grid={{ vertical: true }}
                                        slotProps={{
                                            legend: {
                                                hidden: true,
                                            },
                                        }}
                                        margin={{ left: 20 + formatEncuestaAnswersAlternativesSize(selectedQuestion, encuestasQuestions), top: 10, right: 20 }}
                                        {...{
                                            xAxis: [{
                                                label: 'Respuestas',
                                            }],
                                            width: 700,
                                            height: 500,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <EncuestaAnswersTable
                                        data={Object.keys(selectedQuestion.answers).map(user => {
                                            return {
                                                id: user,
                                                answer: getChosenAlternatives(selectedQuestion.answers[user]).length !== 0 ?
                                                    getChosenAlternatives(selectedQuestion.answers[user]).map(a => encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase())["alternative_" + a.toLowerCase()]).join(", ") :
                                                    "—"
                                            }
                                        })}
                                        title={"Respuestas"}
                                    />
                                </Grid>
                            </Grid>
                        : (selectedQuestion.type === "Pregunta Abierta" || selectedQuestion.type === "Pregunta abierta") ?
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant='p' mb={"40px"}>{encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()).question}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <EncuestaAnswersTable
                                        data={Object.keys(selectedQuestion.answers).map(user => {
                                            return {
                                                id: user,
                                                answer: selectedQuestion.answers[user] === "unanswered" ? "—" : selectedQuestion.answers[user]
                                            }
                                        })}
                                        title={"Respuestas"}
                                    />
                                </Grid>
                            </Grid>
                        : selectedQuestion.type === "Cinta" ?
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant='p' mb={"40px"}>{encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()).question + (encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()).question_detail !== "—" ? " — " + encuestasQuestions.find(q => q.code === selectedQuestion.id.toLowerCase()).question_detail : "")}</Typography>
                                    <BarChart
                                        dataset={formatEncuestaAnswersCinta(selectedQuestion.answers, selectedQuestion, encuestasQuestions)}
                                        xAxis={[{
                                            scaleType: 'band',
                                            dataKey: 'label',
                                            tickLabelStyle: {
                                                fontSize: 10,
                                            },
                                        }]}
                                        series={[{ dataKey: 'value' }]}
                                        colors={['#d23831']}
                                        grid={{ vertical: true }}
                                        slotProps={{
                                            legend: {
                                                hidden: true,
                                            },
                                        }}
                                        margin={{ left: 20, top: 10, right: 20 }}
                                        {...{
                                            yAxis: [{
                                                label: 'Respuestas',
                                            }],
                                            width: 700,
                                            height: 500,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <EncuestaAnswersTable
                                        data={Object.keys(selectedQuestion.answers).map(user => {
                                            return {
                                                id: user,
                                                answer: selectedQuestion.answers[user] === "unanswered" ? "—" : selectedQuestion.answers[user]
                                            }
                                        })}
                                        title={"Respuestas"}
                                    />
                                </Grid>
                            </Grid>
                        :
                        <Typography variant='p-small' textAlign={"center"}>Esta pregunta no se encuentra en la base de datos de preguntas de encuestas.</Typography>
                    }
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default EncuestaModal;