import React, { useState } from 'react';
import { Grid, Box, CircularProgress, Typography, List, ListItem, ListItemButton, ListItemText, Divider } from '@mui/material';
import CalificacionesTable from '../tables/CalificacionesTable';


const CourseCalificaciones = ({
    course,
    courseData,
    loading,
    selectedImplementation,
    selectedSection,
}) => {

    const [calificacionesTab, setCalificacionesTab] = useState("Resumen");
    const [tabReport, setTabReport] = useState(null);

    const handleCalificacionesTabClick = (tab, tabReport) => {
        setCalificacionesTab(tab);
        setTabReport(tabReport)
    }

    const formatPreguntas = (preguntas) => {
        let formattedPruebas = {};
        for (let answer of Object.values(preguntas.data)) {
            for (let key of Object.keys(answer)) {
                if (!preguntas.metadata.preguntas.includes(key)) {
                    continue;
                }
                if (formattedPruebas[key] == null) {
                    formattedPruebas[key] = {
                        "name": key,
                        "questions": {}
                    };
                    var preguntaNumber = 1
                    for (let key2 of Object.keys(answer[key])) {
                        if (key2.includes("Pregunta Calificada ") || key2.includes("Pregunta calificada ")) {
                            formattedPruebas[key].questions[key2] = {
                                "id": key2.replace("Pregunta Calificada ", "").replace("Pregunta calificada ", ""),
                                "answers": {}
                            };
                        } else {
                            formattedPruebas[key].questions[key2] = {
                                "id": "P" + preguntaNumber.toString(),
                                "block_id": key2,
                                "answers": {}
                            };
                        }
                        preguntaNumber += 1
                    }
                }
                for (let key2 of Object.keys(answer[key])) {
                    if (!formattedPruebas[key].questions[key2]) {
                        formattedPruebas[key].questions[key2] = {
                            "id": key2,
                            "answers": {}
                        };
                    }
                    if (answer[key][key2] != null) {
                        formattedPruebas[key].questions[key2].answers[answer["Usuario edX"]] = {
                            "answer": answer[key][key2],
                            "is_correct": false
                        }
                    }
                }
            }
        }
        return formattedPruebas;
    }

    return (
        selectedImplementation === "none" ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p' textAlign={"center"}>Por favor seleccione una implementación.</Typography>
            </Box>
            : selectedSection === "none" ?
                <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                    <Typography variant='p' textAlign={"center"}>Por favor seleccione una sección.</Typography>
                </Box>
                : loading ?
                    <Box width={"100%"} textAlign={"center"} mt={"200px"}>
                        <CircularProgress sx={{ "color": "#d23831" }} />
                    </Box>
                    : Object.keys(courseData).length === 0 ?
                        <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                            <Typography variant='p' textAlign={"center"}>No hay información disponible para esta implementación.</Typography>
                        </Box>
                        : courseData[Object.keys(courseData)[selectedSection]].report == null ?
                            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                                <Typography variant='p' textAlign={"center"}>No hay datos de calificaciones disponibles para esta implementación.</Typography>
                            </Box>
                            :
                            <Grid container spacing={2} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                                <Grid item xs={12} md={2}>
                                    <List>
                                        <ListItem key={"Resumen"} disablePadding sx={{ "backgroundColor": calificacionesTab === "Resumen" ? "#60696C" : "white" }}>
                                            <ListItemButton onClick={() => handleCalificacionesTabClick("Resumen", null)}>
                                                <Typography variant="p" sx={{ "textAlign": "center", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={calificacionesTab === "Resumen" ? "white" : "#646464"}>Resumen de calificaciones</Typography>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                    <Divider />
                                    {courseData[Object.keys(courseData)[selectedSection]].report["reporte_tests"]["metadata"] != null &&
                                        <>
                                            <Typography variant="p" sx={{
                                                color: "#646464",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                fontSize: "0.9em",
                                                marginTop: "15px",
                                                fontFamily: "Avenir Heavy"
                                            }}>Tests</Typography>
                                            <List>
                                                {courseData[Object.keys(courseData)[selectedSection]].report["reporte_tests"]["metadata"]["preguntas"].sort().map(test => (
                                                    <ListItem key={test} disablePadding sx={{ "backgroundColor": calificacionesTab === test ? "#60696C" : "white" }}>
                                                        <ListItemButton onClick={() => handleCalificacionesTabClick(test, "reporte_tests")}>
                                                            <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={calificacionesTab === test ? "white" : "#646464"}>{test}</Typography>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </>
                                    }
                                    <Divider />
                                    {courseData[Object.keys(courseData)[selectedSection]].report["reporte_controles"]["metadata"] != null &&
                                        <>
                                            <Typography variant="p" sx={{
                                                color: "#646464",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                fontSize: "0.9em",
                                                marginTop: "15px",
                                                fontFamily: "Avenir Heavy"
                                            }}>Controles</Typography>
                                            <List>
                                                {courseData[Object.keys(courseData)[selectedSection]].report["reporte_controles"]["metadata"]["preguntas"].sort().map(control => (
                                                    <ListItem key={control} disablePadding sx={{ "backgroundColor": calificacionesTab === control ? "#60696C" : "white" }}>
                                                        <ListItemButton onClick={() => handleCalificacionesTabClick(control, "reporte_controles")}>
                                                            <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={calificacionesTab === control ? "white" : "#646464"}>{control}</Typography>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </>
                                    }
                                    <Divider />
                                    {courseData[Object.keys(courseData)[selectedSection]].report["reporte_preguntas_calificadas"]["metadata"] != null &&
                                        <>
                                            <Typography variant="p" sx={{
                                                color: "#646464",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                fontSize: "0.9em",
                                                marginTop: "15px",
                                                fontFamily: "Avenir Heavy"
                                            }}>Preguntas calificadas</Typography>
                                            <List>
                                                {courseData[Object.keys(courseData)[selectedSection]].report["reporte_preguntas_calificadas"]["metadata"]["preguntas"].sort().map(preguntas_calificada => (
                                                    <ListItem key={preguntas_calificada} disablePadding sx={{ "backgroundColor": calificacionesTab === preguntas_calificada ? "#60696C" : "white" }}>
                                                        <ListItemButton onClick={() => handleCalificacionesTabClick(preguntas_calificada, "reporte_preguntas_calificadas")}>
                                                            <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={calificacionesTab === preguntas_calificada ? "white" : "#646464"}>{preguntas_calificada}</Typography>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </>
                                    }
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    {calificacionesTab == null ?
                                        <Box width={"100%"} textAlign={"center"}>
                                            <Typography variant='p' textAlign={"center"}>Por favor seleccione una pestaña.</Typography>
                                        </Box>
                                        : calificacionesTab === "Resumen" ?
                                            <Box>
                                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                    <Typography variant='p' textAlign={"center"}>Resumen de calificaciones</Typography>
                                                </Box>
                                            </Box>
                                            :
                                            <Box>
                                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                    <CalificacionesTable
                                                        prueba={formatPreguntas(courseData[Object.keys(courseData)[selectedSection]].report[tabReport])[calificacionesTab]}
                                                        users={Object.values(courseData[Object.keys(courseData)[selectedSection]].report["reporte_completo"]).map(item => item["Usuario edX"])}
                                                        tallerTitle={tabReport === "reporte_preguntas_calificadas"}
                                                    />
                                                </Box>
                                            </Box>
                                    }
                                </Grid>
                            </Grid>
    );
}

export default CourseCalificaciones;