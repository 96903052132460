import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { IconButton, Box, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EncuestaModal from '../modals/EncuestaModal';
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px'
    },
}));

const EncuestaTable = ({ course, selectedImplementation, selectedSection, encuesta, encuestasQuestions, users }) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const [detailModal, setDetailModal] = useState(false);

    const columns = [
        {
            field: 'number',
            disableColumnMenu: true,
            headerName: 'N°',
            sortable: false,
            width: 60,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'id',
            disableColumnMenu: true,
            headerName: 'ID',
            sortable: false,
            width: 180,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.id.toLowerCase()
        },
        {
            field: 'question',
            disableColumnMenu: true,
            headerName: 'Pregunta',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'left',
            valueGetter: (params, row) =>
                encuestasQuestions.find(q => q.code === row.id.toLowerCase()) ?
                    (encuestasQuestions.find(q => q.code === row.id.toLowerCase()).question_detail !== "—" ? encuestasQuestions.find(q => q.code === row.id.toLowerCase()).question + " — " + encuestasQuestions.find(q => q.code === row.id.toLowerCase()).question_detail : encuestasQuestions.find(q => q.code === row.id.toLowerCase()).question)
                    : ""
        },
        {
            field: 'type',
            disableColumnMenu: true,
            headerName: 'Tipo',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'answers_total',
            disableColumnMenu: true,
            headerName: 'N° Respuestas',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => {
                let answers = Object.keys(row.answers);
                for (let answer of answers) {
                    if (row.answers[answer] === "unanswered") {
                        answers = answers.filter(a => a !== answer);
                    }
                }
                return answers.length;
            }
        },
        {
            field: 'answers_rate',
            disableColumnMenu: true,
            headerName: 'Tasa de respuesta',
            sortable: false,
            width: 140,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => {
                let answers = Object.keys(row.answers);
                for (let answer of answers) {
                    if (row.answers[answer] === "unanswered") {
                        answers = answers.filter(a => a !== answer);
                    }
                }
                return (100 * (answers.length / users.length)).toString().slice(0, 4) + "%"
            }
        },
        {
            field: 'detail',
            disableColumnMenu: true,
            headerName: 'Detalles',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <IconButton
                    onClick={() => { setSelectedQuestion(params.row); setDetailModal(params.row) }}
                >
                    <MoreHorizIcon /> 
                </IconButton>
            ),
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px 10px' }}>
                <Typography variant='table-title'>{encuesta.name}</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: '20px 0' }}>
            {encuesta && <StyledDataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={Object.values(encuesta.questions)}
                columns={columns}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
            }
            {selectedQuestion && <EncuestaModal
                open={detailModal !== false}
                handleClose={() => setDetailModal(false)}
                title={encuesta.name + " — " + selectedQuestion.number + " — " + selectedQuestion.id}
                subtitle={course.id + " — " + course.implementations[selectedImplementation].semester + " — " + course.implementations[selectedImplementation].type + " — " + course.implementations[selectedImplementation].sections[selectedSection].location + course.implementations[selectedImplementation].sections[selectedSection].section}
                width="xl"
                selectedQuestion={selectedQuestion}
                encuestasQuestions={encuestasQuestions}
            />
            }
        </div>
    );
};

export default EncuestaTable;
