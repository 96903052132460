import React, { useState } from 'react';
import { Grid, Box, CircularProgress, Typography, List, ListItem, ListItemButton, ListItemText, Divider } from '@mui/material';
import EncuestaIndicator from '../indicators/EncuestaIndicator';
import EncuestaTable from '../tables/EncuestaTable';


const DiplomadoEncuestas = ({
    course,
    courseData,
    selectedImplementation,
    selectedSection,
    loading,
    encuestasQuestions
}) => {

    const [encuestasTab, setEncuestasTab] = useState("Resumen");

    const handleEncuestasTabClick = (name) => {
        setEncuestasTab(name);
    }

    const formatEncuestas = (encuestas) => {
        let formattedEncuestas = {};
        for (let answer of Object.values(encuestas)) {
            let encuestaId = answer["Encuesta N°"];
            let questionId = answer["Identificador"];
            let codigo = answer["pregunta"];
            let answerId = answer["Nombre"];
            if (encuestaId === "Encuesta N°") {
                continue;
            }
            if (formattedEncuestas[encuestaId] == null) {
                formattedEncuestas[encuestaId] = {
                    "name": answer["Encuesta"].charAt(0).toUpperCase() + answer["Encuesta"].slice(1),
                    "id": encuestaId,
                    "questions": {}
                };
            };
            if (formattedEncuestas[encuestaId]["questions"][questionId] == null) {
                formattedEncuestas[encuestaId]["questions"][questionId] = {
                    "answers": {},
                    "number": questionId,
                    "id": codigo,
                    "type": encuestasQuestions.find(q => q.code === codigo.toLowerCase()) ? encuestasQuestions.find(q => q.code === codigo.toLowerCase()).type : "-",
                    "answers_total": 0,
                    "answers_rate": 0,
                };
            };
            formattedEncuestas[encuestaId]["questions"][questionId]["answers"][answerId] = answer["Respuesta"];
        }
        return formattedEncuestas;
    }

    return (
        selectedImplementation === "none" ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p' textAlign={"center"}>Por favor seleccione una implementación.</Typography>
            </Box>
            : selectedSection === "none" ?
                <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                    <Typography variant='p' textAlign={"center"}>Por favor seleccione una sección.</Typography>
                </Box>
                : (loading || encuestasQuestions == null) ?
                    <Box width={"100%"} textAlign={"center"} mt={"200px"}>
                        <CircularProgress sx={{ "color": "#d23831" }} />
                    </Box>
                    : Object.keys(courseData).length === 0 ?
                        <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                            <Typography variant='p' textAlign={"center"}>No hay información disponible para esta implementación.</Typography>
                        </Box>
                        : (courseData[Object.keys(courseData)[selectedSection]].encuesta == null || courseData[Object.keys(courseData)[selectedSection]].encuesta == null) ?
                            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                                <Typography variant='p' textAlign={"center"}>No hay datos de encuestas disponibles para esta implementación.</Typography>
                            </Box>
                            :
                            <Grid container spacing={2} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                                <Grid item xs={12} md={2}>
                                    <List>
                                        <ListItem key={"Resumen"} disablePadding sx={{ "backgroundColor": encuestasTab === "Resumen" ? "#60696C" : "white" }}>
                                            <ListItemButton onClick={() => handleEncuestasTabClick("Resumen")}>
                                                <Typography variant="p" sx={{ "textAlign": "center", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={encuestasTab === "Resumen" ? "white" : "#646464"}>Resumen de encuestas</Typography>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem key={"Asistencia"} disablePadding sx={{ "backgroundColor": encuestasTab === "Asistencia" ? "#60696C" : "white" }}>
                                            <ListItemButton onClick={() => handleEncuestasTabClick("Asistencia")}>
                                                <Typography variant="p" sx={{ "textAlign": "center", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={encuestasTab === "Asistencia" ? "white" : "#646464"}>Asistencia</Typography>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem key={"Otros instrumentos"} disablePadding sx={{ "backgroundColor": encuestasTab === "Otros instrumentos" ? "#60696C" : "white" }}>
                                            <ListItemButton onClick={() => handleEncuestasTabClick("Otros instrumentos")}>
                                                <Typography variant="p" sx={{ "textAlign": "center", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={encuestasTab === "Otros instrumentos" ? "white" : "#646464"}>Otros instrumentos</Typography>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                    <Divider />
                                    <Typography variant="p" sx={{
                                        color: "#646464",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: "0.9em",
                                        marginTop: "15px",
                                        fontFamily: "Avenir Heavy"
                                    }}>Encuestas</Typography>
                                    <List>
                                        {Object.values(formatEncuestas(courseData[Object.keys(courseData)[selectedSection]].encuesta.encuestas)).map(encuesta => (
                                            <ListItem key={encuesta.name} disablePadding sx={{ "backgroundColor": encuestasTab === encuesta.name ? "#60696C" : "white" }}>
                                                <ListItemButton onClick={() => handleEncuestasTabClick(encuesta.name)}>
                                                    <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={encuestasTab === encuesta.name ? "white" : "#646464"}>{encuesta.name}</Typography>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    {encuestasTab == null ?
                                        <Box width={"100%"} textAlign={"center"}>
                                            <Typography variant='p' textAlign={"center"}>Por favor seleccione una encuesta.</Typography>
                                        </Box>
                                        : encuestasTab === "Resumen" ?
                                            <Box>
                                                <Typography variant="p" sx={{ "textAlign": "center", "margin": "20px 0" }}>Resumen de encuestas</Typography>
                                            </Box>
                                            : encuestasTab === "Asistencia" ?
                                                <Box>
                                                    <Typography variant="p" sx={{ "textAlign": "center", "margin": "20px 0" }}>Asistencia</Typography>
                                                </Box>
                                                : encuestasTab === "Otros instrumentos" ?
                                                    <Box>
                                                        <Typography variant="p" sx={{ "textAlign": "center", "margin": "20px 0" }}>Otros instrumentos</Typography>
                                                    </Box>
                                                    :
                                                    <Box>
                                                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                            <EncuestaTable
                                                                course={course}
                                                                selectedImplementation={selectedImplementation}
                                                                selectedSection={selectedSection}
                                                                encuesta={Object.values(formatEncuestas(courseData[Object.keys(courseData)[selectedSection]].encuesta.encuestas)).find(encuesta => encuesta.name === encuestasTab)}
                                                                encuestasQuestions={encuestasQuestions}
                                                                users={Object.values(courseData[Object.keys(courseData)[selectedSection]].report["reporte_completo"]).map(item => item["Usuario edX"])}
                                                            />
                                                        </Box>
                                                    </Box>
                                    }
                                </Grid>
                            </Grid>
    );

}

export default DiplomadoEncuestas;