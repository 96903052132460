import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CancelIcon from '@mui/icons-material/Cancel';
import { downloadOutputFileCourse } from '../../../../requests/downloadOutputFileCourse';
import { downloadOutputFileDiplomado } from '../../../../requests/downloadOutputFileDiplomado';


const formatTimestamp = (timestamp) => {
    const date = isNaN(Number(timestamp)) ? new Date(timestamp) : new Date(Number(timestamp));
    const formatter = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: false
    });
    return formatter.format(date);
}

const OutputTable = ({ keycloak, course, implementation, data, isDiplomado, selectedSection }) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'id',
            disableColumnMenu: true,
            headerName: isDiplomado ? 'Módulo' : 'Sección',
            sortable: false,
            width: 80,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'report',
            disableColumnMenu: true,
            headerName: 'Reporte',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.row.report
                        ? (
                            isDiplomado ?
                                <DownloadIcon sx={{ cursor: "pointer", color: "#088408" }} onClick={
                                    async () => await downloadOutputFileDiplomado(
                                        keycloak.token,
                                        course.id,
                                        course.modules[params.row.id].implementations[implementation].semester,
                                        params.row.id,
                                        course.modules[params.row.id].implementations[implementation].sections[selectedSection].section,
                                        "report")
                                } />
                                :
                                <DownloadIcon sx={{ cursor: "pointer", color: "#088408" }} onClick={
                                    async () => await downloadOutputFileCourse(
                                        keycloak.token,
                                        course.id,
                                        course.implementations[implementation].semester,
                                        course.implementations[implementation].type,
                                        params.row.id,
                                        "report")
                                } />
                        )
                        : <CancelIcon sx={{ color: "#ff0000" }} />
                    }
                </Box>
            )
        },
        {
            field: 'encuesta',
            disableColumnMenu: true,
            headerName: 'Encuestas',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.row.encuesta
                        ? (
                            isDiplomado ?
                                <DownloadIcon sx={{ cursor: "pointer", color: "#088408" }} onClick={
                                    async () => await downloadOutputFileDiplomado(
                                        keycloak.token,
                                        course.id,
                                        course.modules[params.row.id].implementations[implementation].semester,
                                        params.row.id,
                                        course.modules[params.row.id].implementations[implementation].sections[selectedSection].section,
                                        "encuesta")
                                } />
                                :
                                <DownloadIcon sx={{ cursor: "pointer", color: "#088408" }} onClick={
                                    async () => await downloadOutputFileCourse(
                                        keycloak.token,
                                        course.id,
                                        course.implementations[implementation].semester,
                                        course.implementations[implementation].type,
                                        params.row.id,
                                        "encuesta")
                                } />
                        )
                        : <CancelIcon sx={{ color: "#ff0000" }} />
                    }
                </Box>
            )
        },
        {
            field: 'respuestas',
            disableColumnMenu: true,
            headerName: 'Respuestas',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.row.respuestas
                        ? (
                            isDiplomado ?
                                <DownloadIcon sx={{ cursor: "pointer", color: "#088408" }} onClick={
                                    async () => await downloadOutputFileDiplomado(
                                        keycloak.token,
                                        course.id,
                                        course.modules[params.row.id].implementations[implementation].semester,
                                        params.row.id,
                                        course.modules[params.row.id].implementations[implementation].sections[selectedSection].section,
                                        "respuestas")
                                } />
                                :
                                <DownloadIcon sx={{ cursor: "pointer", color: "#088408" }} onClick={
                                    async () => await downloadOutputFileCourse(
                                        keycloak.token,
                                        course.id,
                                        course.implementations[implementation].semester,
                                        course.implementations[implementation].type,
                                        params.row.id,
                                        "respuestas")
                                } />
                        )
                        : <CancelIcon sx={{ color: "#ff0000" }} />
                    }
                </Box>
            )
        },
        {
            field: 'structure',
            disableColumnMenu: true,
            headerName: 'Estructura',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.row.structure
                        ? (
                            isDiplomado ?
                                <DownloadIcon sx={{ cursor: "pointer", color: "#088408" }} onClick={
                                    async () => await downloadOutputFileDiplomado(
                                        keycloak.token,
                                        course.id,
                                        course.modules[params.row.id].implementations[implementation].semester,
                                        params.row.id,
                                        course.modules[params.row.id].implementations[implementation].sections[selectedSection].section,
                                        "structure")
                                } />
                                :
                                <DownloadIcon sx={{ cursor: "pointer", color: "#088408" }} onClick={
                                    async () => await downloadOutputFileCourse(
                                        keycloak.token,
                                        course.id,
                                        course.implementations[implementation].semester,
                                        course.implementations[implementation].type,
                                        params.row.id,
                                        "strcture")
                                } />
                        )
                        : <CancelIcon sx={{ color: "#ff0000" }} />
                    }
                </Box>
            )
        },
        {
            field: 'last_update',
            disableColumnMenu: true,
            headerName: 'Última actualización',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.row.last_update ? formatTimestamp(params.row.last_update) : "—"}
                </Box>
            )
        },
    ];

    function makeRows() {
        let rows = [];
        if (isDiplomado) {
            for (let key of Object.keys(data)) {
                rows.push({
                    id: key,
                    report: data[key][Object.keys(data[key])[selectedSection]].report != null,
                    encuesta: data[key][Object.keys(data[key])[selectedSection]].encuesta != null,
                    respuestas: data[key][Object.keys(data[key])[selectedSection]].respuestas != null,
                    structure: data[key][Object.keys(data[key])[selectedSection]].structure != null,
                    last_update: data[key][Object.keys(data[key])[selectedSection]].last_update
                });
            }
        } else {
            for (let key of Object.keys(data)) {
                rows.push({
                    id: key,
                    report: data[key].report != null,
                    encuesta: data[key].encuesta != null,
                    respuestas: data[key].respuestas != null,
                    structure: data[key].structure != null,
                    last_update: data[key].last_update
                });
            }
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Datos disponibles</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        Object.keys(data).length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p' textAlign={"center"}>No hay datos disponibles para esta implementación.</Typography>
            </Box>
            :
            <div style={{ width: '750px', maxWidth: "750px" }}>
                <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>
    );
};

export default OutputTable;
