const matomoConfiguration = {
    urlBase: process.env.REACT_APP_MATOMO_URL,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    disabled: false,
    heartBeat: {
        active: true,
        seconds: 10
    },
    linkTracking: false,
    configurations: {
        disableCookies: true,
        setSecureCookie: process.env.REACT_APP_ENV === 'development' ? false : true,
        setRequestMethod: 'POST'
    }
}

export default matomoConfiguration;