import React from 'react';
import { Divider, Card, Grid, Typography, CircularProgress, Box, Link } from '@mui/material';


const DiplomadoReviewIndicator = ({ diplomado, preparing }) => {

    return (
        <Card sx={{ "margin": "10px 10px", "minWidth": "500px", "width": "500px"}}>
            <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ "height": "30px", "backgroundColor": "#d23831", "paddingTop": "10px" }}>
                <Typography variant={'p'} color="white" fontWeight={"bold"} gutterBottom width={"100%"} textAlign={"center"} fontSize={"0.8em"}>
                    Información general
                </Typography>
            </Grid>
            <Box fontSize={"0.8em"}>
                {preparing ?
                    <Box textAlign={"center"}>
                        <CircularProgress sx={{ "color": "#d23831" }} />
                    </Box>
                    :
                    <>
                        <Grid container display={"flex"} p={"0 20px"} justifyContent={"space-between"} mb={"10px"}>
                            <Grid item xs={6} md={5} m={"10px 0"}>
                                <Typography fontWeight={"bold"} variant="p">Plataforma</Typography>
                                <Divider />
                                <Typography variant="p">Sitio</Typography>
                                <Typography variant="p">Implementación más reciente</Typography>
                            </Grid>
                            <Grid item xs={6} md={7} m={"10px 0"}>
                                <Typography mt={"19px"} variant="p"></Typography>
                                <Divider />
                                <Typography textAlign={"center"} variant="p">
                                    {diplomado.site === "redfid" ? "RedFID" : diplomado.site === "cmmeduformacion" ? "CMMEdu Formación" : "No hay sitio"}
                                </Typography>
                                <Typography textAlign={"center"} variant="p">
                                    {diplomado.modules[Object.keys(diplomado.modules)[0]].implementations[Object.keys(diplomado.modules[Object.keys(diplomado.modules)[0]].implementations)[Object.keys(diplomado.modules[Object.keys(diplomado.modules)[0]].implementations).length - 1]].semester}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                }
            </Box>
        </Card>
    );

}

export default DiplomadoReviewIndicator;