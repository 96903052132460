import React from 'react';
import { Typography, Grid } from '@mui/material';


const Users = () => {

    return (
        <>
            <Typography fontSize={"1.6em"} mb={"10px"} variant="title">Usuarios</Typography>
            <Typography fontSize={"1.0em"} variant="title">Base de datos de inscritos en al menos un curso</Typography>
            <Grid container spacing={2}>
            
            </Grid>
        </>
    );
}

export default Users;