import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography, Grid, Box } from '@mui/material';
import line1 from '../../assets/img/line1.png';
import line2 from '../../assets/img/line2.png';
import lineExtra from '../../assets/img/line_extra.png';


const Historia = ({ }) => {

  const { trackPageView } = useMatomo();

  useEffect(() => {
    document.title = "Suma y Sigue | Historia";
    trackPageView();
  }, []);

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header current={"historia"} />
      <Grid
        p={"0 20px"}
        component="div"
        sx={{
          flexGrow: 1,
          m: '30px auto',
          maxWidth: '940px',
          width: '100%',
        }}
      >
        <Typography variant='subtitle' fontWeight={"bold"} m={"20px 0"} textAlign={"center"}>
          HISTORIA
        </Typography>
        <Typography variant='p' m={"20px 0"}>
          El desarrollo e implementación de cursos del programa Suma y Sigue se ha realizado desde el año 2015, a través de una colaboración
          sostenida con el Ministerio de Educación. En un comienzo con la División de Educación General (DEG), y desde el 2018 con el Centro
          de Perfeccionamiento, Experimentación e Investigaciones Pedagógicas (CPEIP).
        </Typography>
        <Typography variant='p' m={"20px 0"}>
          Esta alianza de largo aliento ha permitido consolidar un programa de desarrollo profesional docente con una propuesta didáctica coherente
          desde Educación Básica hasta Educación Media, instalando procesos de mejora continua en el desarrollo de los cursos e incorporando nuevas
          temáticas en base a la evidencia de la investigación y de su implementación.
        </Typography>
        <Typography variant='p' m={"20px 0"}>
          Se han diseñado diferentes modalidades para impartir el programa con el fin de considerar las distintas realidades de los docentes y su
          contexto, como también atendiendo a los lineamientos de políticas públicas en educación.
        </Typography>
        <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
          DESARROLLO DEL PROGRAMA SUMA Y SIGUE
        </Typography>
        <Grid container display={"flex"} alignItems={"center"} justifyContent={"space-between"} mt={"20px"} rowGap={2}>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              Programa Suma y Sigue para docentes de Educación Básica.
            </Typography>
          </Grid>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              Programa Suma y Sigue para docentes de Educación Media.
            </Typography>
          </Grid>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              Diplomas de Postítulo basados en el programa Suma y Sigue.
            </Typography>
          </Grid>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              Cursos enfocados en la preparación de la enseñanza y evaluación del aprendizaje.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              component='img'
              sx={{
                width: "100%",
                marginTop: "20px"
              }}
              alt="Historia"
              src={line1}
            />
          </Grid>
          <Grid item xs={2.8}>
          </Grid>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              Adaptación a modalidad e-learning.
            </Typography>
          </Grid>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              Primer curso orientado al Plan de Formación Diferenciada H-C.
            </Typography>
          </Grid>
          <Grid item xs={2.8}>
          </Grid>
        </Grid>
        <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
          IMPLEMENTACIÓN DEL PROGRAMA SUMA Y SIGUE
        </Typography>
        <Grid container display={"flex"} alignItems={"center"} justifyContent={"space-between"} mt={"20px"} rowGap={2}>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              Piloto del programa Suma y Sigue.
            </Typography>
          </Grid>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              <b>Estrategia territorial</b> junto a Servicios Locales de Educación y Universidades regionales.
            </Typography>
          </Grid>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              <b>Estrategia nacional</b> en modalidad e-learning, llegando a regiones de todo el país.
            </Typography>
          </Grid>
          <Grid item xs={2.8}>
          </Grid>
          <Grid item xs={12}>
            <Box
              component='img'
              sx={{
                width: "100%",
                marginTop: "20px"
              }}
              alt="Historia"
              src={line2}
            />
          </Grid>
        </Grid>
        <Grid container display={"flex"} alignItems={"start"} justifyContent={"space-between"} mt={"20px"} rowGap={2}>
          <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection="column">
            <Box
              component='img'
              sx={{
                width: "30%",
                marginRight: "50px",
                marginBottom: "10px"
              }}
              alt="Historia"
              src={lineExtra}
            />
            <Box sx={{ marginRight: "50px", width: "40%", padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "60px" }}>
              <Typography variant='p-small' textAlign={"center"}>
                Adaptación a modalidad e-learning.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2.8}>
          </Grid>
          <Grid item xs={2.8} sx={{ padding: "10px", backgroundColor: "#EEEEEE", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "150px" }}>
            <Typography variant='p-small' textAlign={"center"}>
              Estrategia mixta a través de ambas modalidades de implementación. Se realizan alianzas con 5 Servicios Locales de Educación.
            </Typography>
          </Grid>
          <Grid item xs={2.8}>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );

};

export default Historia;