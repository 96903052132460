import React from "react";
import Chip from '@mui/material/Chip';


export const getLevelChip = (level) => {
    var name = "";
    var color = "";
    switch (level) {
        case "basica":
            name = "Educación básica";
            color = "#00b761"
            break;
        case "media":
            name = "Educación media";
            color = "#595959"
            break;
        case "fid":
            name = "Formación inicial docente";
            color = "#40b4ba"
            break;
        default:
            name = "Otro";
            color = "#646464"
            break;
    }
    return <Chip sx={{"backgroundColor": color, "color": "white", "fontWeight": "bold"}} p={0} label={name} />
}

export const getTopicChip = (topic) => {
    var name = "";
    var color = "";
    switch (topic) {
        case "numeros":
            name = "Números";
            color = "#e36a5b"
            break;
        case "algebra":
            name = "Álgebra";
            color = "#83cce5"
            break;
        case "geometria":
            name = "Geometría";
            color = "#b282dc"
            break;
        case "datos":
            name = "Datos y azar";
            color = "#9b5f1e"
            break;
        default:
            name = "Otro";
            color = "#646464"
            break;
    }
    return <Chip sx={{"backgroundColor": color, "color": "white", "fontWeight": "bold"}} p={0} label={name} />
}

