import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography, Box, Grid, TextField, MenuItem, Button, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import regions from '../../assets/json/regions.json';
import { validateRut, validateEmail } from '../../utils/validators';


const TrayectoriasForm = ({ }) => {

    const { trackPageView, trackEvent } = useMatomo();

    const [question1, setQuestion1] = useState("");
    const [question2, setQuestion2] = useState("");
    const [question3, setQuestion3] = useState("");
    const [question4, setQuestion4] = useState("none");
    const [question5, setQuestion5] = useState("none");
    const [question6, setQuestion6] = useState("");
    const [question7, setQuestion7] = useState("none");
    const [question8, setQuestion8] = useState("");
    const [question9, setQuestion9] = useState("");
    const [question10, setQuestion10] = useState("none");
    const [question11, setQuestion11] = useState("none");
    const [question12, setQuestion12] = useState("none");
    const [question13, setQuestion13] = useState("none");
    const [question14A, setQuestion14A] = useState(false);
    const [question14B, setQuestion14B] = useState(false);
    const [question14C, setQuestion14C] = useState(false);
    const [question14D, setQuestion14D] = useState(false);
    const [question14E, setQuestion14E] = useState(false);
    const [question14F, setQuestion14F] = useState(false);
    const [question15, setQuestion15] = useState("none");
    const [question16, setQuestion16] = useState("none");
    const [question17, setQuestion17] = useState("none");
    const [question18, setQuestion18] = useState("none");
    const [question19, setQuestion19] = useState("none");
    const [question20, setQuestion20] = useState("none");
    const [question21A, setQuestion21A] = useState(false);
    const [question21B, setQuestion21B] = useState(false);
    const [question21C, setQuestion21C] = useState(false);
    const [question21D, setQuestion21D] = useState(false);
    const [question21E, setQuestion21E] = useState(false);
    const [question21F, setQuestion21F] = useState(false);
    const [question21G, setQuestion21G] = useState(false);
    const [question21H, setQuestion21H] = useState(false);
    const [question21I, setQuestion21I] = useState(false);
    const [question21J, setQuestion21J] = useState(false);
    const [question21K, setQuestion21K] = useState(false);
    const [question21L, setQuestion21L] = useState(false);
    const [question22, setQuestion22] = useState("");
    const [errors, setErrors] = useState({});

    const [navPage, setNavPage] = useState(1);

    const handleNavigationAndValidation = (current, action) => {
        if (current === 1 && action === "next") {
            const newErrors = {}
            if (question1 === "") {
                newErrors.question1 = "Por favor indique su nombre.";
            } else if (question1.length < 3 || question1.length > 50) {
                newErrors.question1 = "Por favor indique un nombre entre 3 y 50 caracteres.";
            }
            if (question2 === "") {
                newErrors.question2 = "Por favor indique su RUT.";
            } else if (!validateRut(question2)) {
                newErrors.question2 = "Por favor ingrese un RUT válido (sin puntos y con guion).";
            }
            if (question3 === "") {
                newErrors.question3 = "Por favor indique su correo electrónico.";
            } else if (!validateEmail(question3)) {
                newErrors.question3 = "Por favor ingrese un correo electrónico válido.";
            }
            if (question4 === "none") {
                newErrors.question4 = "Por favor seleccione una región.";
            }
            if (question5 === "none") {
                newErrors.question5 = "Por favor seleccione una comuna.";
            }
            if (question6 === "") {
                newErrors.question6 = "Por favor indique el nombre del establecimiento en el que trabaja.";
            } else if (question6.length < 3 || question6.length > 100) {
                newErrors.question6 = "Por favor indique un nombre de establecimiento entre 3 y 100 caracteres.";
            }
            if (question7 === "none") {
                newErrors.question7 = "Por favor indique su género.";
            }
            if (question8 === "") {
                newErrors.question8 = "Por favor indique su edad.";
            } else if (isNaN(parseInt(question8)) || parseInt(question8) < 18 || parseInt(question8) > 100) {
                newErrors.question8 = "La edad debe ser un número entre 18 y 100.";
            }
            if (question9 === "") {
                newErrors.question9 = "Por favor indique los años de experiencia como docente.";
            } else if (isNaN(parseInt(question9)) || parseInt(question9) < 0 || parseInt(question9) > 100) {
                newErrors.question9 = "Los años de experiencia deben ser un número entre 0 y 100.";
            }
            setErrors(newErrors);
            if (Object.keys(newErrors).length === 0) {
                setNavPage(2);
                window.scrollTo(0, 0);
            }
        } else if (current === 2 && action === "next") {
            const newErrors = {}
            if (question10 === "none") {
                newErrors.question10 = "Por favor seleccione una opción.";
            }
            if (question10 === "Profesor(a) de Educación General Básica" || question10 === "Educador(a) Diferencial" || question10 === "Educador(a) de Párvulos") {
                if (question11 === "none") {
                    newErrors.question11 = "Por favor seleccione una opción.";
                }
                if (question11 === "Sí" && question12 === "none") {
                    newErrors.question12 = "Por favor seleccione una opción.";
                }
            }
            if (question10 === "Profesor(a) de Educación Media" || question10 === "No tengo título en el área de educación" || question11 === "No" || question12 !== "none") {
                if (question13 === "none") {
                    newErrors.question13 = "Por favor seleccione una opción.";
                }
                if (question13 === "Sí" && !question14A && !question14B && !question14C && !question14D && !question14E && !question14F) {
                    newErrors.question14 = "Por favor seleccione al menos una opción.";
                }
            }
            if (question15 === "none") {
                newErrors.question15 = "Por favor seleccione una opción.";
            }
            if (question15 === "No") {
                if (question16 === "none") {
                    newErrors.question16 = "Por favor seleccione una opción.";
                }
                if (question16 === "No") {
                    if (question17 === "none") {
                        newErrors.question17 = "Por favor seleccione una opción.";
                    }
                    if (question17 === "Sí") {
                        if (question19 === "none") {
                            newErrors.question19 = "Por favor seleccione una opción.";
                        }
                    }
                }
            } else if (question15 === "Sí") {
                if (question18 === "none") {
                    newErrors.question18 = "Por favor seleccione una opción.";
                }
            }
            if (question20 === "none") {
                newErrors.question20 = "Por favor seleccione una opción.";
            }
            if (question22 === "") {
                newErrors.question22 = "Por favor explique por qué desea cursar una trayectoria.";
            } else if (question22.length < 20 || question22.length > 500) {
                newErrors.question22 = "Por favor explique por qué desea cursar una trayectoria entre 10 y 500 caracteres.";
            }
            setErrors(newErrors);
            if (Object.keys(newErrors).length === 0) {
                let answers = {
                    "1": question1,
                    "2": question2,
                    "3": question3,
                    "4": question4,
                    "5": question5,
                    "6": question6,
                    "7": question7,
                    "8": question8,
                    "9": question9,
                    "10": question10,
                    "11": question11,
                    "12": question12,
                    "13": question13,
                    "14": {
                        "A": question14A,
                        "B": question14B,
                        "C": question14C,
                        "D": question14D,
                        "E": question14E,
                        "F": question14F
                    },
                    "15": question15,
                    "16": question16,
                    "17": question17,
                    "18": question18,
                    "19": question19,
                    "20": question20,
                    "21": {
                        "A": question21A,
                        "B": question21B,
                        "C": question21C,
                        "D": question21D,
                        "E": question21E,
                        "F": question21F,
                        "G": question21G,
                        "H": question21H,
                        "I": question21I,
                        "J": question21J,
                        "K": question21K,
                        "L": question21L
                    },
                    "22": question22
                }
                console.log(answers);
                setNavPage(3);
                trackEvent({
                    category: 'Trayectorias',
                    action: 'Completó cuestionario',
                    name: "Trayectoria resultante: "
                });
                window.scrollTo(0, 0);
            }
        } else if (current === 2 && action === "back") {
            setNavPage(1);
            window.scrollTo(0, 0);
        } else if (current === 3 && action === "back") {
            setNavPage(2);
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        document.title = "Suma y Sigue | Cuestionario de trayectorias formativas";
        trackPageView();
    }, []);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header current={"trayectorias"} />
            <Grid
                p={"0 20px"}
                component="div"
                sx={{
                flexGrow: 1,
                m: '30px auto',
                maxWidth: '940px',
                width: '100%',
                }}
            >
                <Typography variant='subtitle' fontWeight={"bold"} m={"20px 0"} textAlign={"center"}>
                    CUESTIONARIO DE TRAYECTORIAS FORMATIVAS
                </Typography>
                <Typography variant='p' textAlign={"center"} m={"20px 0 40px 0"}>
                    Responda el siguiente cuestionario para conocer la trayectoria formativa que más se ajusta a sus necesidades.
                </Typography>
                {navPage === 1 && <Grid container spacing={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>1</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Indique su nombre:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question1}
                            onChange={(e) => {
                                if (e.target.value.length > 50) return;
                                setQuestion1(e.target.value);
                                setErrors({ ...errors, question1: "" });
                            }}
                            fullWidth
                            error={!!errors.question1}
                            helperText={errors.question1}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>2</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Indique su RUT:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question2}
                            onChange={(e) => {
                                if (e.target.value.length > 10) return;
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9Kk-]/g, "");
                                setQuestion2(filteredValue);
                                setErrors({ ...errors, question2: "" });
                            }}
                            fullWidth
                            error={!!errors.question2}
                            helperText={errors.question2}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>3</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Indique su correo electrónico:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question3}
                            onChange={(e) => {
                                setQuestion3(e.target.value);
                                setErrors({ ...errors, question3: "" });
                            }}
                            fullWidth
                            error={!!errors.question3}
                            helperText={errors.question3}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>4</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Región:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            select
                            value={question4}
                            onChange={(e) => {
                                setQuestion5("none");
                                setQuestion4(e.target.value);
                                setErrors({ ...errors, question4: "", question5: "" });
                            }}
                            fullWidth
                            error={!!errors.question4}
                            helperText={errors.question4}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            {regions.regions.map((region) => (
                                <MenuItem key={region.name} value={region.name}>
                                    {region.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>5</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Comuna:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            select
                            value={question5}
                            onChange={(e) => {
                                setQuestion5(e.target.value);
                                setErrors({ ...errors, question5: "" });
                            }}
                            fullWidth
                            error={!!errors.question5}
                            helperText={errors.question5}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una {question4 === "none" ? "región" : "opción"}...</MenuItem>
                            {question4 !== "none" && regions.regions.find(region => region.name === question4).comunas.map((comuna) => (
                                <MenuItem key={comuna} value={comuna}>
                                    {comuna}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>6</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Nombre del establecimiento en el que trabaja:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question6}
                            onChange={(e) => {
                                if (e.target.value.length > 100) return;
                                setQuestion6(e.target.value);
                                setErrors({ ...errors, question6: "" });
                            }}
                            fullWidth
                            error={!!errors.question6}
                            helperText={errors.question6}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>7</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            ¿Cuál es su género?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            select
                            value={question7}
                            onChange={(e) => {
                                setQuestion7(e.target.value);
                                setErrors({ ...errors, question7: "" });
                            }}
                            fullWidth
                            error={!!errors.question7}
                            helperText={errors.question7}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="Masculino">Masculino</MenuItem>
                            <MenuItem value="Femenino">Femenino</MenuItem>
                            <MenuItem value="Otro">Otro</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>8</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            ¿Cuál es su edad?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question8}
                            onChange={(e) => {
                                if (e.target.value.length > 3) return;
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9]/g, "");
                                setQuestion8(filteredValue);
                                setErrors({ ...errors, question8: "" });
                            }}
                            fullWidth
                            error={!!errors.question8}
                            helperText={errors.question8}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>9</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            ¿Cuántos años de experiencia tiene como docente?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question9}
                            onChange={(e) => {
                                if (e.target.value.length > 3) return;
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9]/g, "");
                                setQuestion9(filteredValue);
                                setErrors({ ...errors, question9: "" });
                            }}
                            fullWidth
                            error={!!errors.question9}
                            helperText={errors.question9}
                        />
                    </Grid>
                    <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"end"} mt={"20px"}>
                        <Button variant="red" onClick={() => handleNavigationAndValidation(1, "next")}>Siguiente</Button>
                    </Grid>
                </Grid>
                }
                {navPage === 2 &&
                    <Grid container spacing={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item xs={2} md={1}>
                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>10</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <Typography ml={"10px"} variant='p'>
                                Indique el nombre que más se acerque a su título en el área de educación:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <TextField
                                variant="outlined"
                                value={question10}
                                onChange={(e) => {
                                    setQuestion10(e.target.value);
                                    setErrors({ ...errors, question10: "" });
                                }}
                                fullWidth
                                select
                                error={!!errors.question10}
                                helperText={errors.question10}
                            >
                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                <MenuItem value="Profesor(a) de Educación General Básica">Profesor(a) de Educación General Básica</MenuItem>
                                <MenuItem value="Educador(a) Diferencial">Educador(a) Diferencial</MenuItem>
                                <MenuItem value="Educador(a) de Párvulos">Educador(a) de Párvulos</MenuItem>
                                <MenuItem value="Profesor(a) de Educación Media">Profesor(a) de Educación Media</MenuItem>
                                <MenuItem value="No tengo título en el área de educación">No tengo título en el área de educación</MenuItem>
                            </TextField>
                        </Grid>
                        {(question10 === "Profesor(a) de Educación General Básica" || question10 === "Educador(a) Diferencial" || question10 === "Educador(a) de Párvulos") &&
                            <>
                                <Grid item xs={2} md={1}>
                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>11</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10} md={4}>
                                    <Typography ml={"10px"} variant='p'>
                                        ¿Usted posee alguna mención, ya sea en su pregrado u obtenida a través de postítulo?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        value={question11}
                                        onChange={(e) => {
                                            setQuestion11(e.target.value);
                                            setErrors({ ...errors, question11: "" });
                                        }}
                                        fullWidth
                                        select
                                        error={!!errors.question11}
                                        helperText={errors.question11}
                                    >
                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                        <MenuItem value="Sí">Sí, poseo una mención</MenuItem>
                                        <MenuItem value="No">No, no poseo ninguna mención</MenuItem>
                                    </TextField>
                                </Grid>
                                {question11 === "Sí" &&
                                    <>
                                        <Grid item xs={2} md={1}>
                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>12</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Typography ml={"10px"} variant='p'>
                                                ¿Usted posee una mención en matemática?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                variant="outlined"
                                                value={question12}
                                                onChange={(e) => {
                                                    setQuestion12(e.target.value);
                                                    setErrors({ ...errors, question12: "" });
                                                }}
                                                fullWidth
                                                select
                                                error={!!errors.question12}
                                                helperText={errors.question12}
                                            >
                                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                <MenuItem value="Sí">Sí, poseo una mención en matemática</MenuItem>
                                                <MenuItem value="No">No, no poseo mención en matemática</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                        {(question10 === "Profesor(a) de Educación Media" || question10 === "No tengo título en el área de educación" || question11 === "No" || question12 !== "none") &&
                            <>
                                <Grid item xs={2} md={1}>
                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>13</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10} md={4}>
                                    <Typography ml={"10px"} variant='p'>
                                        ¿Ha realizado cursos de Desarrollo Profesional Docente en el área de matemática?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        value={question13}
                                        onChange={(e) => {
                                            setQuestion13(e.target.value);
                                            setErrors({ ...errors, question13: "" });
                                        }}
                                        fullWidth
                                        select
                                        error={!!errors.question13}
                                        helperText={errors.question13}
                                    >
                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                        <MenuItem value="Sí">Sí, he realizado cursos de Desarrollo Profesional Docente en el área de matemática</MenuItem>
                                        <MenuItem value="No">No, no he realizado cursos de Desarrollo Profesional Docente en el área de matemática</MenuItem>
                                    </TextField>
                                </Grid>
                                {question13 === "Sí" &&
                                    <>
                                        <Grid item xs={2} md={1}>
                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>14</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Typography ml={"10px"} variant='p'>
                                                ¿Cuál o cuáles fueron los focos temáticos de estos cursos? Marque los que correspondan:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={question14A}
                                                            onChange={(e) => {
                                                                setQuestion14A(e.target.checked);
                                                                setErrors({ ...errors, question14: "" });
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } sx={{ "color": '#646464' }} label="Datos y azar" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={question14B}
                                                            onChange={(e) => {
                                                                setQuestion14B(e.target.checked);
                                                                setErrors({ ...errors, question14: "" });
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } sx={{ "color": '#646464' }} label="Geometría" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={question14C}
                                                            onChange={(e) => {
                                                                setQuestion14C(e.target.checked);
                                                                setErrors({ ...errors, question14: "" });
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } sx={{ "color": '#646464' }} label="Medición" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={question14D}
                                                            onChange={(e) => {
                                                                setQuestion14D(e.target.checked);
                                                                setErrors({ ...errors, question14: "" });
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } sx={{ "color": '#646464' }} label="Probabilidades" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={question14E}
                                                            onChange={(e) => {
                                                                setQuestion14E(e.target.checked);
                                                                setErrors({ ...errors, question14: "" });
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } sx={{ "color": '#646464' }} label="Números y operaciones" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={question14F}
                                                            onChange={(e) => {
                                                                setQuestion14F(e.target.checked);
                                                                setErrors({ ...errors, question14: "" });
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } sx={{ "color": '#646464' }} label="Patrones y álgebra" />
                                            </FormGroup>
                                            {errors.question14 && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.question14}</span>}
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                        {(question13 === "No" || question14A || question14B || question14C || question14D || question14E || question14F) &&
                            <>
                                <Grid item xs={2} md={1}>
                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>15</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10} md={4}>
                                    <Typography ml={"10px"} variant='p'>
                                        ¿Imparte actualmente clases de matemática?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        value={question15}
                                        onChange={(e) => {
                                            setQuestion15(e.target.value);
                                            setErrors({ ...errors, question15: "" });
                                        }}
                                        fullWidth
                                        select
                                        error={!!errors.question15}
                                        helperText={errors.question15}
                                    >
                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                        <MenuItem value="Sí">Sí, imparto clases de matemática</MenuItem>
                                        <MenuItem value="No">No, no imparto clases de matemática</MenuItem>
                                    </TextField>
                                </Grid>
                                {question15 === "No" &&
                                    <>
                                        <Grid item xs={2} md={1}>
                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>16</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Typography ml={"10px"} variant='p'>
                                                ¿Ha impartido anteriormente clases de matemática?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                variant="outlined"
                                                value={question16}
                                                onChange={(e) => {
                                                    setQuestion16(e.target.value);
                                                    setErrors({ ...errors, question16: "" });
                                                }}
                                                fullWidth
                                                select
                                                error={!!errors.question16}
                                                helperText={errors.question16}
                                            >
                                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                <MenuItem value="Sí">Sí, he impartido clases de matemática</MenuItem>
                                                <MenuItem value="No">No, no he impartido clases de matemática</MenuItem>
                                            </TextField>
                                        </Grid>
                                        {question16 === "No" &&
                                            <>
                                                <Grid item xs={2} md={1}>
                                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>17</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10} md={4}>
                                                    <Typography ml={"10px"} variant='p'>
                                                        ¿Hará en un próximo semestre clases de matemática?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={7}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={question17}
                                                        onChange={(e) => {
                                                            setQuestion17(e.target.value);
                                                            setErrors({ ...errors, question17: "" });
                                                        }}
                                                        fullWidth
                                                        select
                                                        error={!!errors.question17}
                                                        helperText={errors.question17}
                                                    >
                                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                        <MenuItem value="Sí">Sí, haré clases de matemática</MenuItem>
                                                        <MenuItem value="No">No, no haré clases de matemática</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                {question17 === "Sí" &&
                                                    <>
                                                        <Grid item xs={2} md={1}>
                                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>19</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10} md={4}>
                                                            <Typography ml={"10px"} variant='p'>
                                                                ¿En qué ciclo escolar hará clases de matemática?
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={7}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={question19}
                                                                onChange={(e) => {
                                                                    setQuestion19(e.target.value);
                                                                    setErrors({ ...errors, question19: "" });
                                                                }}
                                                                fullWidth
                                                                select
                                                                error={!!errors.question19}
                                                                helperText={errors.question19}
                                                            >
                                                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                                <MenuItem value="Primer ciclo">Primer ciclo</MenuItem>
                                                                <MenuItem value="Segundo ciclo">Segundo ciclo</MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {(question15 === "Sí" || (question15 === "No" && question16 === "Sí")) &&
                                    <>
                                        <Grid item xs={2} md={1}>
                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>18</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Typography ml={"10px"} variant='p'>
                                                ¿En qué ciclo escolar ha hecho o hace clases de matemática?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                variant="outlined"
                                                value={question18}
                                                onChange={(e) => {
                                                    setQuestion18(e.target.value);
                                                    setErrors({ ...errors, question18: "" });
                                                }}
                                                fullWidth
                                                select
                                                error={!!errors.question18}
                                                helperText={errors.question18}
                                            >
                                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                <MenuItem value="Primer ciclo">Primer ciclo</MenuItem>
                                                <MenuItem value="Segundo ciclo">Segundo ciclo</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </>
                                }
                                {(question18 !== "none" || question19 !== "none") &&
                                    <>
                                        <Grid item xs={2} md={1}>
                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>20</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Typography ml={"10px"} variant='p'>
                                                ¿En qué tema matemático le gustaria fortalecer su conocimiento matemático para enseñar?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                variant="outlined"
                                                value={question20}
                                                onChange={(e) => {
                                                    setQuestion20(e.target.value);
                                                    setErrors({ ...errors, question20: "" });
                                                }}
                                                fullWidth
                                                select
                                                error={!!errors.question20}
                                                helperText={errors.question20}
                                            >
                                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                <MenuItem value="Datos y azar">Datos y azar</MenuItem>
                                                <MenuItem value="Geometría">Geometría</MenuItem>
                                                <MenuItem value="Medición">Medición</MenuItem>
                                                <MenuItem value="Números y operaciones">Números y operaciones</MenuItem>
                                                <MenuItem value="Patrones y álgebra">Patrones y álgebra</MenuItem>
                                            </TextField>
                                        </Grid>
                                        {question20 !== "none" &&
                                            <>
                                                <Grid item xs={2} md={1}>
                                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>21</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10} md={4}>
                                                    <Typography ml={"10px"} variant='p'>
                                                        ¿Ha realizado alguno de estos cursos del programa Suma y Sigue?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={7}>
                                                    {question20 === "Datos y azar" ?
                                                    <>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21A}
                                                                        onChange={(e) => {
                                                                            setQuestion21A(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Iniciando el Pensamiento Estadístico" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21B}
                                                                        onChange={(e) => {
                                                                            setQuestion21B(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Desarrollando el Pensamiento Estadístico" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21C}
                                                                        onChange={(e) => {
                                                                            setQuestion21C(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Trabajando con la Incerteza y la Probabilidad" />
                                                        </FormGroup>
                                                    </>
                                                : question20 === "Geometría" ?
                                                    <>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21D}
                                                                        onChange={(e) => {
                                                                            setQuestion21D(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Iniciando el Pensamiento Geométrico" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21E}
                                                                        onChange={(e) => {
                                                                            setQuestion21E(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Desarrollando el Pensamiento Geométrico" />
                                                        </FormGroup>
                                                    </>
                                                : question20 === "Medición" ?
                                                    <>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21F}
                                                                        onChange={(e) => {
                                                                            setQuestion21F(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Trabajando con la Medida y la Medición" />
                                                        </FormGroup>
                                                    </>
                                                    : question20 === "Números y operaciones" ?
                                                    <>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21G}
                                                                        onChange={(e) => {
                                                                            setQuestion21G(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Trabajando con el Sistema de Numeración Decimal y las Situaciones Aditivas" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21H}
                                                                        onChange={(e) => {
                                                                            setQuestion21H(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Trabajando con Fracciones y sus Representaciones" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21I}
                                                                        onChange={(e) => {
                                                                            setQuestion21I(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Trabajando con Fracciones, Razones y Porcentajes" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21J}
                                                                        onChange={(e) => {
                                                                            setQuestion21J(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Trabajando con Números Racionales" />
                                                        </FormGroup>
                                                    </>
                                                    : question20 === "Patrones y álgebra" ?
                                                    <>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21K}
                                                                        onChange={(e) => {
                                                                            setQuestion21K(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Desarrollando el Pensamiento Algebraico" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={question21L}
                                                                        onChange={(e) => {
                                                                            setQuestion21L(e.target.checked);
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                } sx={{ "color": '#646464' }} label="Analizando Relaciones Proporcionales y Gráficos" />
                                                        </FormGroup>
                                                    </>
                                                    : 
                                                    <></>
                                                }
                                                </Grid>
                                                <Grid item xs={2} md={1}>
                                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>22</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10} md={4}>
                                                    <Typography ml={"10px"} variant='p'>
                                                        ¿Por qué desea cursar una trayectoria del programa Suma y Sigue?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={7}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={question22}
                                                        onChange={(e) => {
                                                            if (e.target.value.length > 500) return;
                                                            setQuestion22(e.target.value);
                                                            setErrors({ ...errors, question22: "" });
                                                        }}
                                                        fullWidth
                                                        multiline
                                                        rows={6}
                                                        error={!!errors.question22}
                                                        helperText={errors.question22}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }

                        <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"start"} mt={"20px"}>
                            <Button variant="red" onClick={() => handleNavigationAndValidation(2, "back")}>Anterior</Button>
                        </Grid>
                        <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"end"} mt={"20px"}>
                            <Button variant="red" onClick={() => handleNavigationAndValidation(2, "next")}>Siguiente</Button>
                        </Grid>
                    </Grid>
                }
                {navPage === 3 &&
                    <Grid container spacing={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"start"} mt={"20px"}>
                            <Button variant="red" onClick={() => handleNavigationAndValidation(3, "back")}>Anterior</Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Footer />
        </Grid>
    );

}

export default TrayectoriasForm;