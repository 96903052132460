import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, CircularProgress, Button } from '@mui/material';
import ExplorerForm from '../forms/ExplorerForm';
import { formatCourseStructure } from '../../../../utils/formatters';
import XblockResults from '../indicators/XblockResults';


const DiplomadoExplorador = ({
    course,
    courseData,
    loading,
    selectedImplementation,
    selectedSection,
}) => {

    const [courseSection, setCourseSection] = useState("none");
    const [courseSubsection, setCourseSubsection] = useState("none");
    const [courseUnit, setCourseUnit] = useState("none");
    const [courseXblock, setCourseXblock] = useState("none");
    const [loadingExploration, setLoadingExploration] = useState(false);
    const [explorerResults, setExplorerResults] = useState(null);

    const handleExplore = () => {
        setLoadingExploration(true);
        let structure = formatCourseStructure(courseData[Object.keys(courseData)[selectedSection]].structure)
        if (courseXblock !== "none") {
            console.log("Xblock: ", structure[courseSection].subsections[courseSubsection].units[courseUnit].xblocks[courseXblock].block_id);
            setExplorerResults(<XblockResults xblock={structure[courseSection].subsections[courseSubsection].units[courseUnit].xblocks[courseXblock]} />);
        }
        if (courseUnit !== "none") {
            console.log("Unidad: ", structure[courseSection].subsections[courseSubsection].units[courseUnit].unit);
        }
        if (courseSubsection !== "none") {
            console.log("Subsección: ", structure[courseSection].subsections[courseSubsection].subsection);
        }
        if (courseSection !== "none") {
            console.log("Sección: ", structure[courseSection].section);
        }
        setLoadingExploration(false);
    }

    useEffect(() => {
        setCourseSection("none");
        setCourseSubsection("none");
        setCourseUnit("none");
        setCourseXblock("none");
    }, [course]);

    return (
        selectedImplementation === "none" ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p' textAlign={"center"}>Por favor seleccione una implementación.</Typography>
            </Box>
            : selectedSection === "none" ?
                <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                    <Typography variant='p' textAlign={"center"}>Por favor seleccione una sección.</Typography>
                </Box>
                : loading ?
                    <Box width={"100%"} textAlign={"center"} mt={"200px"}>
                        <CircularProgress sx={{ "color": "#d23831" }} />
                    </Box>
                    : courseData == null ?
                        <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                            <Typography variant='p' textAlign={"center"}>No hay información disponible para esta implementación.</Typography>
                        </Box>
                        : (courseData[Object.keys(courseData)[selectedSection]].structure && courseData[Object.keys(courseData)[selectedSection]].respuestas) ?
                            <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"center"} mb={"20px"}>
                                <Grid item xs={12}>
                                    <ExplorerForm
                                        structure={formatCourseStructure(courseData[Object.keys(courseData)[selectedSection]].structure)}
                                        courseSection={courseSection}
                                        setCourseSection={setCourseSection}
                                        courseSubsection={courseSubsection}
                                        setCourseSubsection={setCourseSubsection}
                                        courseUnit={courseUnit}
                                        setCourseUnit={setCourseUnit}
                                        courseXblock={courseXblock}
                                        setCourseXblock={setCourseXblock}
                                        loadingExploration={loadingExploration}
                                        handleExplore={handleExplore}
                                    />
                                </Grid>
                                <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Button variant='red' disabled={courseSection === "none"} onClick={() => handleExplore()}>Explorar</Button>
                                </Grid>
                                {explorerResults && <Grid item xs={12}>
                                    {explorerResults}
                                </Grid>
                                }
                            </Grid>
                            :
                            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                                <Typography variant='p' textAlign={"center"}>La información de la sección seleccionada está incompleta.</Typography>
                            </Box>
    );

}

export default DiplomadoExplorador;