import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const EncuestaAnswersTable = ({ data, title }) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'id',
            disableColumnMenu: true,
            headerName: 'Usuario',
            sortable: true,
            width: 180,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'answer',
            disableColumnMenu: true,
            headerName: 'Respuesta',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'left'
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px 10px' }}>
                <Typography variant='table-title'>{title}</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: '20px 0' }}>
            <StyledDataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={data}
                columns={columns}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default EncuestaAnswersTable;
