import axios from 'axios';

export const makeSeguimiento = async (token, studentProfileFile, oraReportFile, studentStateFile, encuestas2022, experimental, subirDrive) => {

    try {

        const formData = new FormData();
        formData.append("student_profile", studentProfileFile);
        formData.append("ora_report", oraReportFile);
        formData.append("student_state", studentStateFile);
        formData.append("encuestas2022", encuestas2022);
        formData.append("experimental", experimental);
        formData.append("subir_drive", subirDrive);
        const response = await axios.post(process.env.REACT_APP_API_URL + "/seguimiento", formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });

        switch (response.status) {
            case 200:
                return { result: response.data, error: "" };
            default:
                return { result: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { result: null, error: "Error desconocido: " + error.message };
    }
}