import React from 'react';
import { Grid, Box, Typography, Link } from '@mui/material';
import logoFCFM from '../assets/img/fcfm.png';


const Footer = () => {

    return (
        <Box width={"100%"} justifyContent={"center"} backgroundColor={"#d23831"} boxShadow={"0px 6px 4px -4px #cccccc;"} display={"flex"} alignItems={"center"} sx={{ backgroundColor: "#60696C" }}>
            <Grid width={"940px"} container columnSpacing={4} pt={"60px"} pb={"20px"} fontSize={"0.8em"}>
                <Grid item xs={12} sm={3} display="flex" justifyContent="space-between" alignItems="center">
                    <Box
                        component="img"
                        src={logoFCFM}
                        alt="FCFM"
                        sx={{ maxWidth: "160px" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} display="flex" justifyContent="center" alignItems="center" flexDirection={"column"} pt={"20px"} pb={"20px"}>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        Información de contacto
                    </Typography>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        Facultad de Ciencias Físicas y Matemáticas de la Universidad de Chile, Beauchef 851, Edificio Norte - Piso 6. Santiago, Chile.
                    </Typography>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        Teléfono: +56 2 2978 4483
                    </Typography>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        Correo electrónico: <Link sx={{color: "white", textDecoration: "none"}} href={"mailto:cmm-e@dim.uchile.cl"}>cmm-e@dim.uchile.cl</Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={5} display="flex" justifyContent="center" alignItems="center" flexDirection={"column"}>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        Sitios destacados:
                    </Typography>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        <Link sx={{color: "white", textDecoration: "none"}} target="_blank" href={"https://www.mineduc.cl"}>Ministerio de Educación</Link>
                    </Typography>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        <Link sx={{color: "white", textDecoration: "none"}} target="_blank" href={"https://uchile.cl"}>Universidad de Chile</Link>
                    </Typography>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        <Link sx={{color: "white", textDecoration: "none"}} target="_blank" href={"https://ingenieria.uchile.cl"}>Facultad de Ciencias Físicas y Matemáticas (FCFM)</Link>
                    </Typography>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        <Link sx={{color: "white", textDecoration: "none"}} target="_blank" href={"https://cmm.uchile.cl"}>Centro de Modelamiento Matemático (CMM)</Link>
                    </Typography>
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} m={1}>
                        <Link sx={{color: "white", textDecoration: "none"}} target="_blank" href={"https://ciae.uchile.cl"}>Centro de Investigación Avanzada en Educación (CIAE)</Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Typography variant='p' fontWeight={"bold"} color={"white"} width={"100%"} textAlign={"center"} m={1}>
                        Copyright © {new Date().getFullYear()} Suma y Sigue - CMMEdu
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer;