import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SummaryTable = ({ data }) => {

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            maxWidth: 300,
            color: "#646464",
            fontSize: theme.typography.pxToRem(12),
            fontFamily: "Avenir Regular",
            textAlign: 'justify',
            padding: "15px",
            border: '1px solid #dadde9',
        },
    }));

    const [paginationModel, setPaginationModel] = useState({ pageSize: 20, page: 0 });

    function makeColumns(data) {
        let columns = [
            {
                field: 'id',
                headerName: 'Curso',
                flex: 1,
                headerAlign: 'center',
                disableColumnMenu: true,
                sortable: false,
                align: 'center'

            }
        ];
        let cols = [];
        for (let key of Object.keys(data)) {
            if (data[key].topic !== "diplomado_1" && data[key].topic !== "diplomado_2") {
                for (let imp of data[key].implementations) {
                    if (!cols.includes(imp.semester)) {
                        cols.push(imp.semester)
                    }

                }
            }
        }
        cols.sort();
        for (let key of cols) {
            let column = {
                field: key,
                headerName: key,
                headerAlign: 'center',
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    return (

                        <Box height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            {params.row[key] != null ?
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{params.row.id} — Implementación {key}</Typography>
                                            {
                                                params.row[key].sections > 0 ?
                                                    <Typography variant="p">
                                                        Secciones: {params.row[key].sections}
                                                    </Typography>
                                                    :
                                                    <Typography variant="p">
                                                        No hay datos de secciones de esta implementación en el catálogo de cursos.
                                                    </Typography>
                                            }
                                        </React.Fragment>
                                    }
                                >
                                    <CheckCircleIcon sx={{ color: "green" }}></CheckCircleIcon>
                                </HtmlTooltip>
                                :
                                <Typography>—</Typography>
                            }
                        </Box>
                    );
                }
            }
            columns.push(column);
        }
        return columns;
    }

    function makeRows(data) {
        let rows = [];
        for (let key of Object.keys(data)) {
            if (data[key].topic !== "diplomado_1" && data[key].topic !== "diplomado_2") {
                let row = {
                    id: key
                }
                for (let imp of data[key].implementations) {
                    if (row[imp.semester] == null) {
                        row[imp.semester] = { sections: imp.sections.length };
                    } else {
                        row[imp.semester].sections += imp.sections.length;
                    }
                }
                rows.push(row);
            }
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Implementaciones de cursos Suma y Sigue</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={makeRows(data)}
                columns={makeColumns(data)}
                pageSizeOptions={[20]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default SummaryTable;
