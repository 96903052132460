import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography, Grid, Box, List, ListItem } from '@mui/material';
import CoursePreview from './components/CoursePreview';
import sndImage from '../../assets/img/SND.png';
import tmdImage from '../../assets/img/TMD.png';
import tfrImage from '../../assets/img/TFR.png';
import trfImage from '../../assets/img/TRF.png';
import tnrImage from '../../assets/img/TNR.png';
import dpaImage from '../../assets/img/DPA.png';
import arpImage from '../../assets/img/ARP.png';
import ipgImage from '../../assets/img/IPG.png';
import dpgImage from '../../assets/img/DPG.png';
import tmmImage from '../../assets/img/TMM.png';
import ipeImage from '../../assets/img/IPE.png';
import tipImage from '../../assets/img/TIP.png';
import dpeImage from '../../assets/img/DPE.png';


const Basica = ({ }) => {

    const { trackPageView } = useMatomo();

    useEffect(() => {
        document.title = "Suma y Sigue | Cursos de Básica";
        trackPageView();
    }, []);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header current={"basica"}/>
            <Grid
                p={"0 20px"}
                component="div"
                sx={{
                flexGrow: 1,
                m: '30px auto',
                maxWidth: '940px',
                width: '100%',
                }}
            >
                <Typography variant='subtitle' fontWeight={"bold"} m={"20px 0"} textAlign={"center"}>
                    CURSOS DE BÁSICA
                </Typography>
                <Typography variant='p' m={"20px 0 0 0"}>
                    <b>Modalidad</b>: e-learning (a distancia) o b-learning (semi-presencial).
                </Typography>
                <Typography variant='p'>
                    <b>Duración</b>: 34 - 45 horas distribuidas entre 2 a 3 meses.
                </Typography>
                <Typography variant='p' m={"20px 0"}>
                    Los cursos tienen un alto componente de trabajo virtual autónomo basado en el uso de tecnologías y resolución de problemas, que entregan
                    experiencias de aprendizaje activo.
                </Typography>
                <Typography variant='p' m={"20px 0"}>
                    Los cursos cuentan con dos formatos de trabajo:
                </Typography>
                <Box ml={"20px"}>
                    <List sx={{ listStyleType: 'disc' }}>
                        <ListItem sx={{ display: 'list-item', color: '#646464' }}>
                            <Typography variant='p'>
                                <b>Talleres virtuales asincrónicos</b>: espacios de trabajo autónomo enfocados en desarrollar la comprensión matemática de diferentes temas
                                fundamentales del currículum escolar y la construcción de conocimientos a partir de contextos cotidianos, de otras disciplinas, de la
                                enseñanza e imaginativos.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#646464' }}>
                            <Typography variant='p'>
                                <b>Sesiones de trabajo sincrónico</b>: talleres de discusión en los que se promueve la construcción colaborativa de conocimientos y la reflexión
                                docente. Estos pueden ser realizados de manera presencial o virtual.
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
                <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
                    NÚMEROS
                </Typography>
                <Grid container display={"flex"} alignItems={"center"} justifyContent={"start"} flexWrap={"wrap"} spacing={4}>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Trabajando con el Sistema de Numeración Decimal y las Situaciones Aditivas"
                            description="Dirigido a docentes que enseñan matemática en los niveles de NT2 a 2° básico. "
                            image={sndImage}
                            paragraph1="El curso Trabajando con el Sistema de Numeración Decimal y las Situaciones Aditivas se 
                                enfoca en desarrollar los conocimientos matemáticos requeridos para enseñar el sistema de numeración 
                                decimal, la adición y sustracción en los niveles de NT2 a 2° básico."
                            paragraph2="El curso aborda el proceso y las estrategias de conteo, la estimación y comparación de cantidades. 
                                También, el sistema de numeración decimal con sus características y propiedades, valor posicional, composición 
                                y descomposición aditiva, comparación y orden y la recta numérica. Además, se estudian las situaciones aditivas 
                                y distintos modelos de representación, estrategias de cálculo aditivo y la justificación de los algoritmos usuales 
                                de la adición y sustracción."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Trabajando con la Multiplicación y la División de Números Naturales"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 1° a 4° básico. "
                            image={tmdImage}
                            paragraph1="El curso Trabajando con la Multiplicación y la División de Números Naturales se enfoca en desarrollar los 
                            conocimientos matemáticos requeridos para enseñar la multiplicación y división en los niveles de 1° a 4° básico."
                            paragraph2="El curso aborda las situaciones multiplicativas y distintos modelos de representación, propiedades de la 
                            multiplicación y la división, estrategias de cálculo y justificación de los algoritmos usuales. Además profundiza la 
                            resolución de problemas multiplicativos, analizando distintas estrategias. "
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Trabajando con Fracciones y sus Representaciones"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 3° y 6° básico."
                            image={tfrImage}
                            paragraph1="El curso Trabajando con Fracciones y sus Representaciones se enfoca en desarrollar los conocimientos matemáticos 
                            requeridos para enseñar fracciones en los niveles de 3° a 6° básico."
                            paragraph2="El curso aborda modelos de representación y la interpretación de las fracciones a través de distintas situaciones. 
                            También, la ubicación de fracciones en la recta numérica, comparación y densidad de fracciones. Además, estudia la adición y 
                            sustracción de fracciones, enfatizando cómo estas operaciones extienden aquellas definidas en los números naturales."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Trabajando con Fracciones, Razones y Porcentajes"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 5° y 8° básico."
                            image={trfImage}
                            paragraph1="El curso Trabajando con Fracciones, Razones y Porcentajes se enfoca en desarrollar conocimientos matemáticos 
                            requeridos para enseñar estos temas en los niveles de 5° a 8° básico."
                            paragraph2="El curso aborda la multiplicación y división de fracciones, con énfasis en el significado de la multiplicación, 
                            su interpretación como operador y trabajando en diversos contextos. Asimismo, con la división de fracciones se busca extender 
                            los usos y significados definidos para los números naturales. También, se estudian estrategias de cálculo asociadas a estas 
                            operaciones. Además, se estudian los conceptos de razones y porcentajes y la resolución de problemas."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Trabajando con Números Racionales"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 5° y 8° básico."
                            image={tnrImage}
                            paragraph1="El curso Trabajando con Números Racionales se enfoca en desarrollar los conocimientos matemáticos requeridos para 
                            enseñar los números racionales en los niveles de 5° a 8° básico."
                            paragraph2="El curso aborda las fracciones decimales y su representación como número decimal, sus cuatro operaciones aritméticas, 
                            haciendo énfasis en la justificación de algoritmos usuales. También, se estudian estrategias de aproximación y estimación, y las 
                            características y propiedades de los números racionales como sistema numérico."
                        />
                    </Grid>
                </Grid>
                <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
                    ÁLGEBRA
                </Typography>
                <Grid container display={"flex"} alignItems={"center"} justifyContent={"start"} flexWrap={"wrap"} spacing={4}>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Desarrollando el Pensamiento Algebraico"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 5° a 8° básico."
                            image={dpaImage}
                            paragraph1="El curso Desarrollando el Pensamiento Algebraico se enfoca en desarrollar los conocimientos matemáticos requeridos para 
                            enseñar patrones y álgebra en los niveles de 5° a 8° básico."
                            paragraph2="El curso aborda los conceptos de patrón, regularidad y secuencias. Patrones en las tablas de multiplicar y en la tabla del 
                            100, y regularidades aditivas y multiplicativas en secuencias numéricas. Además, aborda lenguaje algebraico, propiedades de las 
                            expresiones algebraicas y sus operaciones. También, la igualdad y sus propiedades, el planteamiento y resolución de ecuaciones mediante 
                            diversos métodos como modelos de barra o de manera algebraica."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Analizando Relaciones Proporcionales y Gráficos"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 7° a 8° básico."
                            image={arpImage}
                            paragraph1="El curso Analizando Relaciones Proporcionales y Gráficos se enfoca en desarrollar los conocimientos matemáticos requeridos 
                            para enseñar proporcionalidad, porcentaje y análisis de gráficos en los niveles de 7° y 8° básico."
                            paragraph2="El curso aborda las nociones de razón, proporción y porcentaje. También, se estudian relaciones de proporcionalidad directa 
                            e inversa en situaciones contextualizadas, mediante el análisis de tablas y gráficos, y la resolución de problemas. Además, aborda el 
                            estudio de relaciones entre variables a partir del análisis de gráficos. "
                        />
                    </Grid>
                </Grid>
                <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
                    GEOMETRÍA
                </Typography>
                <Grid container display={"flex"} alignItems={"center"} justifyContent={"start"} flexWrap={"wrap"} spacing={4}>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Iniciando el Pensamiento Geométrico"
                            description="Dirigido a docentes que enseñan matemática en los niveles de NT2 a 2° básico."
                            image={ipgImage}
                            paragraph1="El curso Iniciando el Pensamiento Geométrico se enfoca en desarrollar los conocimientos matemáticos 
                            requeridos para enseñar geometría en los niveles de NT2 a 2° básico."
                            paragraph2="El curso aborda las relaciones espaciales en el entorno, la posición de objetos y personas, el estudio de vistas, 
                            cortes y redes de cuerpos geométricos. También se inicia el estudio de figuras planas, el análisis de figuras prototípicas, 
                            la definición y clasificaciones de polígonos y ángulos, y construcciones geométricas mediante plegado de papel y otros instrumentos. 
                            Además, el curso aborda el proceso de medir y conceptos básicos asociados a relaciones temporales."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Desarrollando el Pensamiento Geométrico"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 3° a 6° básico."
                            image={dpgImage}
                            paragraph1="El curso Desarrollando el Pensamiento Geométrico se enfoca en desarrollar los conocimientos matemáticos requeridos para 
                            enseñar geometría en los niveles de 3° a 6° básico."
                            paragraph2="El curso aborda la posición de objetos y personas, la proyección en perspectiva, posiciones relativas entre rectas y planos. 
                            Para desarrollar la visualización se analizan cortes de figuras 3D, redes de cuerpos geométricos, sólidos platónicos, característica de 
                            Euler y los movimientos de traslación y rotación de la Tierra y las fases de la Luna. También, se revisan las implicancias de las 
                            clasificaciones inclusivas de cuadriláteros en la definiciones de rombo y cuadrado, y se estudian las propiedades de ángulos en 
                            polígonos. Además, se realizan construcciones geométricas con regla y compás y plegado de papel."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Trabajando con la Medida y la Medición"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 3° a 6° básico."
                            image={tmmImage}
                            paragraph1="El curso Trabajando con la Medida y la Medición se enfoca en desarrollar los conocimientos matemáticos requeridos para enseñar la medición 
                            de diversas magnitudes en los niveles de 3° a 6° básico."
                            paragraph2="El curso aborda el proceso de medir, sus etapas y posibles errores que surgen al realizar mediciones. Se estudia la medición 
                            de longitud, área, volumen, tiempo y ángulos, usando unidades estandarizadas y no estandarizadas, y profundizando en las características 
                            y propiedades de cada una de ellas."
                        />
                    </Grid>
                </Grid>
                <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
                    DATOS Y PROBABILIDADES
                </Typography>
                <Grid container display={"flex"} alignItems={"center"} justifyContent={"start"} flexWrap={"wrap"} spacing={4}>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Iniciando el Pensamiento Estadístico"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 1° a 4° básico."
                            image={ipeImage}
                            paragraph1="El curso Iniciando el Pensamiento Estadístico se enfoca en desarrollar los conocimientos matemáticos requeridos 
                            para enseñar estadística en los niveles de 1° a 4° básico."
                            paragraph2="El curso utiliza el ciclo de investigación para explorar conceptos y procedimientos vinculados al trabajo estadístico. 
                            Se detallan las etapas del ciclo de investigación, se abordan nociones básicas de muestreo y recolección de datos, y se emplean 
                            tablas y gráficos para el análisis de datos. Además, se abordan ideas elementales de aleatoriedad en el contexto de juegos de azar, 
                            junto con una aproximación al enfoque frecuentista de la probabilidad."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Trabajando con la Incerteza y la Probabilidad"
                            description="Dirigido a docentes que enseñan matemática en los niveles de 5° a 8° básico."
                            image={tipImage}
                            paragraph1="El curso Trabajando con la Incerteza y la Probabilidad se enfoca en desarrollar los conocimientos matemáticos requeridos 
                            para enseñar probabilidad en los niveles de 5° a 8° básico."
                            paragraph2="El curso explora el estudio de la probabilidad desde perspectivas subjetivas, frecuentistas y clásicas. Se define la 
                            probabilidad como una medida estandarizada de la posibilidad de que ocurra un evento, y se examinan sus propiedades. Además, se recurre 
                            a frecuencias relativas para aproximar la probabilidad de un suceso, y se llevan a cabo simulaciones de experimentos aleatorios mediante 
                            el uso de dados, monedas y herramientas computacionales. La regla de Laplace y el cálculo de probabilidades también son temas abordados, 
                            junto con un análisis detallado de representaciones que facilitan la exploración del espacio muestral."
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CoursePreview
                            title="Desarrollando el Pensamiento Estadístico"
                            description=" Dirigido a docentes que enseñan matemática en los niveles de 5° a 8° básico."
                            image={dpeImage}
                            paragraph1=" El curso Desarrollando el Pensamiento Estadístico se enfoca en desarrollar los conocimientos matemáticos requeridos para 
                            enseñar estadística en los niveles de 5° a 8° básico."
                            paragraph2="El curso utiliza el ciclo de investigación y sus diferentes etapas para el estudio de los temas. Se abordan nociones y 
                            técnicas estadísticas involucradas en los procesos de recolección y organización de datos a partir de un problema de investigación. 
                            También, se describen y profundizan los conceptos y técnicas estadísticas relacionadas los procesos de visualización y análisis de 
                            datos, y se caracteriza la lectura de gráficos en los distintos niveles requeridos para desarrollar esta competencia. Además, se 
                            abordan las medidas de centralidad y de posición, analizando sus distintas interpretaciones y evaluando su pertinencia en diferentes 
                            situaciones."
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );

};

export default Basica;