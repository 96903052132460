import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useNavigate } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography, Grid, Box, Button } from '@mui/material';

const Trayectorias = ({ }) => {

    const { trackPageView } = useMatomo();

    useEffect(() => {
        document.title = "Suma y Sigue | Trayectorias formativas";
        trackPageView();
    }, []);

    const navigate = useNavigate();

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header current={"trayectorias"} />
            <Grid
                p={"0 20px"}
                component="div"
                sx={{
                flexGrow: 1,
                m: '30px auto',
                maxWidth: '940px',
                width: '100%',
                }}
            >
                <Typography variant='subtitle' fontWeight={"bold"} m={"20px 0"} textAlign={"center"}>
                    TRAYECTORIAS FORMATIVAS
                </Typography>
                <Typography variant='p' m={"20px 0"}>
                    Las trayectorias formativas del Programa Suma y Sigue están centradas en la enseñanza de la matemática y busca
                    potenciar las competencias matemáticas, didácticas y pedagógicas de los docentes, que deben conducir y evaluar
                    los procesos de enseñanza-aprendizaje centrados en el desarrollo de habilidades matemáticas. Estás tienen el
                    propósito de proporcionar a los docentes una serie de experiencias de aprendizaje y reflexión didáctica, fortalecer
                    su conocimiento matemático para enseñar según el contexto laboral en que se desempeñan. Estás sugieren una secuencia
                    de cursos del programa Suma y Sigue para especializarse en la enseñanza de la matemática en un nivel o ciclo escolar
                    y en ejes temáticos.
                </Typography>
                <Box textAlign={"center"}>
                    <Button variant="red" onClick={() => navigate("/trayectorias/formulario")}>Ir al formulario</Button>
                </Box>
            </Grid>
            <Footer />
        </Grid>
    );

};

export default Trayectorias;