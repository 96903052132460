import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import sysLogo from '../assets/img/sys.png';
import cmmeduLogo from '../assets/img/cmmedu.png';
import { useNavigate } from 'react-router-dom';

const Header = ({ current }) => {

    const navigate = useNavigate();

    return (
        <>
            <Box width={"100%"} backgroundColor={"#d23831"} boxShadow={"0px 6px 4px -4px #cccccc;"} display={"flex"} alignItems={"center"}>
                <Box p={"0 20px"} fontSize={"0.95em"} width={current === "admin" ? "96%" : "940px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} m={"auto"}>
                    <Box
                        component="img"
                        sx={{
                            width: 126,
                            cursor: 'pointer',
                            padding: "9px"
                        }}
                        onClick={() => navigate("/")}
                        alt="Suma y Sigue"
                        src={sysLogo}
                    />
                    <Link href="https://cmmedu.uchile.cl" target='_blank' style={{ "display": "flex", "alignItems": "center", padding: "9px" }}>
                        <Box
                            component="img"
                            sx={{
                                width: 126,
                                cursor: 'pointer'
                            }}
                            alt="CMMEdu"
                            src={cmmeduLogo}
                        />
                    </Link>
                </Box>
            </Box>
            <Box width={"100%"} height={6} backgroundColor={"#60696C"}></Box>
            {current !== "admin" &&
                <Box sx={{ display: "flex", padding: "0 40px", maxWidth: "970px", margin: "auto", justifyContent: "space-between" }}>
                    <Typography variant={current === "historia" ? 'header-nav-s' : 'header-nav'} onClick={() => navigate("/historia")}>HISTORIA</Typography>
                    <Typography variant={current === "basica" ? 'header-nav-s' : 'header-nav'} onClick={() => navigate("/basica")}>CURSOS DE BÁSICA</Typography>
                    <Typography variant={current === "media" ? 'header-nav-s' : 'header-nav'} onClick={() => navigate("/media")}>CURSOS DE MEDIA</Typography>
                    <Typography variant={current === "diplomas" ? 'header-nav-s' : 'header-nav'} onClick={() => navigate("/diplomas")}>DIPLOMAS DE POSTÍTULO</Typography>
                    {/* <Typography variant={current === "trayectorias" ? 'header-nav-s' : 'header-nav'} onClick={() => navigate("/trayectorias/")}>TRAYECTORIAS FORMATIVAS</Typography> */}
                </Box>
            }
        </>
    );

};

export default Header;