import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';


const ExplorerForm = ({
    structure,
    courseSection,
    setCourseSection,
    courseSubsection,
    setCourseSubsection,
    courseUnit,
    setCourseUnit,
    courseXblock,
    setCourseXblock,
    loadingExploration,
    handleExplore
}) => {
    
    return (
        <Grid container spacing={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={6} md={3}>
                <TextField
                    label="Sección"
                    value={courseSection}
                    select
                    fullWidth
                    disabled={loadingExploration}
                    onChange={(e) => {
                        setCourseSection(e.target.value);
                        setCourseSubsection("none");
                        setCourseUnit("none");
                        setCourseXblock("none");
                    }}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {structure.map((sec, index) => (
                        <MenuItem key={index} value={index}>{sec.section}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    label="Subsección"
                    value={courseSubsection}
                    select
                    fullWidth
                    disabled={courseSection === "none" || loadingExploration}
                    onChange={(e) => {
                        setCourseSubsection(e.target.value);
                        setCourseUnit("none");
                        setCourseXblock("none");
                    }}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {(courseSection !== "none" && structure[courseSection]) && structure[courseSection].subsections.map((subsec, index) => (
                        <MenuItem key={index} value={index}>{subsec.subsection}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    label="Unidad"
                    value={courseUnit}
                    select
                    fullWidth
                    disabled={courseSubsection === "none" || loadingExploration}
                    onChange={(e) => {
                        setCourseUnit(e.target.value);
                        setCourseXblock("none");
                    }}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {(courseSection !== "none" && courseSubsection !== "none" && structure[courseSection]) && structure[courseSection].subsections[courseSubsection].units.map((unit, index) => (
                        <MenuItem key={index} value={index}>{unit.unit}</MenuItem>
                    ))} 
                </TextField>
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    label="XBlock"
                    value={courseXblock}
                    select
                    fullWidth
                    disabled={courseUnit === "none" || loadingExploration}
                    onChange={(e) => {
                        setCourseXblock(e.target.value);
                    }}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {(courseSection !== "none" && courseSubsection !== "none" && courseUnit !== "none" && structure[courseSection]) && structure[courseSection].subsections[courseSubsection].units[courseUnit].xblocks.map((xblock, index) => (
                        <MenuItem key={index} value={index}>{xblock.block_id}</MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    );
}

export default ExplorerForm;