import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography, Grid, Box, List, ListItem, Link } from '@mui/material';

const Diplomas = ({ }) => {

    const { trackPageView } = useMatomo();

    useEffect(() => {
        document.title = "Suma y Sigue | Diplomas de Postítulo";
        trackPageView();
    }, []);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header current={"diplomas"} />
            <Grid
                p={"0 20px"}
                component="div"
                sx={{
                flexGrow: 1,
                m: '30px auto',
                maxWidth: '940px',
                width: '100%',
                }}
            >
                <Typography variant='subtitle' fontWeight={"bold"} m={"20px 0"} textAlign={"center"}>
                    DIPLOMAS DE POSTÍTULO
                </Typography>
                <Typography variant='p' m={"20px 0"}>
                    Los <b>Diplomas de Postítulo en Enseñanza de la Matemática para el Desarrollo de Habilidades</b> se basan en cursos del programa
                    Suma y Sigue, y buscan potenciar competencias matemáticas y pedagógicas especializadas para conducir y evaluar procesos de
                    enseñanza-aprendizaje de las matemáticas.
                </Typography>
                <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
                    OBJETIVOS
                </Typography>
                <Box ml={"20px"}>
                    <List sx={{ listStyleType: 'disc' }}>
                        <ListItem sx={{ display: 'list-item', color: '#646464' }}>
                            <Typography variant='p'>
                                Fortalecer conocimientos disciplinares especializados para la enseñanza de la matemática, con foco en el desarrollo de habilidades.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#646464' }}>
                            <Typography variant='p'>
                                Desarrollar competencias pedagógicas y didácticas para preparar, implementar y evaluar en el aula procesos de aprendizaje centrados
                                en el desarrollo de habilidades matemáticas.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#646464' }}>
                            <Typography variant='p'>
                                Diseñar, implementar y evaluar una propuesta de innovación en el aula para el aprendizaje de un contenido matemático nuclear del
                                currículum escolar.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#646464' }}>
                            <Typography variant='p'>
                                Desarrollar una actitud reflexiva sobre la propia práctica.
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
                <Typography variant='p' textAlign={"center"} m={"20px 0"}>
                    <b>Para más información, escríbenos al siguiente mail: <Link href={"mailto:cmm-e@dim.uchile.cl"}>cmm-e@dim.uchile.cl</Link></b>
                </Typography>
                <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
                    MÓDULOS PRIMER CICLO BÁSICO
                </Typography>
                <Grid container columnSpacing={2} display={"flex"} alignItems={"start"} justifyContent={"space-between"} mt={"20px"}>
                    <Grid item xs={4}>
                        <Box sx={{
                            backgroundColor: "#d23831",
                            height: "70px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "10px"
                        }}>
                            <Typography fontWeight="bold" color={"white"} textAlign={"center"}>CONOCIMIENTO ESPECIALIZADO PARA LA ENSEÑANZA</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Enseñanza y aprendizaje de los números y las operaciones.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Iniciando el pensamiento geométrico.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Trabajando con la medida y la medición.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Iniciando el pensamiento estadístico.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Enseñanza y aprendizaje de patrones.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            backgroundColor: "#d23831",
                            height: "70px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "10px"
                        }}>
                            <Typography fontWeight="bold" color={"white"} textAlign={"center"}> HERRAMIENTAS PARA CONDUCIR Y EVALUAR PROCESOS DE ENSEÑANZA Y APRENDIZAJE</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "120px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Herramientas para conducir y evaluar procesos de enseñanza y aprendizaje centrados en el desarrollo.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "95px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Innovación educativa para el aprendizaje y desarrollo de habilidades matemáticas.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            backgroundColor: "#d23831",
                            height: "70px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "10px"
                        }}>
                            <Typography fontWeight="bold" color={"white"} textAlign={"center"}>PROYECTO DE INNOVACIÓN EDUCATIVA PARA EL AULA</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "145px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Diseño, desarrollo e implementación de un proyecto de innovación, adecuado al contexto educativo que se desempeñan los y las participantes.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} mt={"20px"}>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "145px",
                            padding: "10px 30px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "start",
                            borderRadius: "8px"
                        }}>
                            <Typography textAlign={"justify"} mb={"20px"} color={"#60696C"}>
                                <b>Duración:</b> 255 horas cronológicas.
                            </Typography>
                            <Typography textAlign={"justify"} color={"#60696C"}>
                                <b>Dirigido a:</b> docentes de Educación Básica, educadores diferenciales, jefes de unidad técnico pedagógica y/o coordinadores pedagógicos.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Typography variant='subtitle' fontWeight={"bold"} m={"40px 0"}>
                    MÓDULOS SEGUNDO CICLO BÁSICO
                </Typography>
                <Grid container columnSpacing={2} display={"flex"} alignItems={"start"} justifyContent={"space-between"} mt={"20px"}>
                    <Grid item xs={4}>
                        <Box sx={{
                            backgroundColor: "#d23831",
                            height: "70px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "10px"
                        }}>
                            <Typography fontWeight="bold" color={"white"} textAlign={"center"}>CONOCIMIENTO ESPECIALIZADO PARA LA ENSEÑANZA</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Desarrollando el pensamiento algebraico.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Probabilidad y estadística.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Trabajando con los números racionales.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Analizando relaciones proporcionales y gráficos.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "70px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Geometría y medición.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            backgroundColor: "#d23831",
                            height: "70px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "10px"
                        }}>
                            <Typography fontWeight="bold" color={"white"} textAlign={"center"}> HERRAMIENTAS PARA CONDUCIR Y EVALUAR PROCESOS DE ENSEÑANZA Y APRENDIZAJE</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "120px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            marginBottom: "10px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Herramientas para conducir y evaluar procesos de enseñanza y aprendizaje centrados en el desarrollo.</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "95px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Innovación educativa para el aprendizaje y desarrollo de habilidades matemáticas.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            backgroundColor: "#d23831",
                            height: "70px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "10px"
                        }}>
                            <Typography fontWeight="bold" color={"white"} textAlign={"center"}>PROYECTO DE INNOVACIÓN EDUCATIVA PARA EL AULA</Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "145px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px"
                        }}>
                            <Typography color={"#60696C"} textAlign={"center"}>Diseño, desarrollo e implementación de un proyecto de innovación, adecuado al contexto educativo que se desempeñan los y las participantes.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} mt={"20px"}>
                        <Box sx={{
                            backgroundColor: "#EEEEEE",
                            height: "145px",
                            padding: "10px 30px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "start",
                            borderRadius: "8px"
                        }}>
                            <Typography textAlign={"justify"} mb={"20px"} color={"#60696C"}>
                                <b>Duración:</b> 255 horas cronológicas.
                            </Typography>
                            <Typography textAlign={"justify"} color={"#60696C"}>
                                <b>Dirigido a:</b> docentes de Educación Básica, educadores diferenciales, jefes de unidad técnico pedagógica y/o coordinadores pedagógicos.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );

};

export default Diplomas;